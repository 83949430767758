import UserService from "../services/user.service";

export const user = {
    namespaced: true,
    state: {
        data: [],
    },
    actions: {

        async addRessRequest({ commit }, formData) {
            try {
              const data = await UserService.addRessources(formData);
              commit("operationSuccess", data);
            } catch (error) {
              commit("operationFailure");
              throw error;
            }
          },
    
        async contactUsRequest({ commit }, data) {
            return UserService.contactUs(data).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
        
        async candidatSpontRequest({ commit }, data) {
            return UserService.candtSpontanee(data).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },

        async addAnnoncesRequest({ commit }, formData) {
            try {
              const response = await UserService.addAnnonces(formData);
              commit('operationSuccess', response);
              return Promise.resolve(response);
            } catch (error) {
              commit('operationFailure', error);
              return Promise.reject(error);
            }
        },

        // async editAnnoncesRequest({ commit }, { id, formData }) {
        //   try {
        //     const response = await UserService.editAnnonces(id, formData);
        //     commit('operationSuccess', response);
        //     return Promise.resolve(response);
        //   } catch (error) {
        //     commit('operationFailure', error);
        //     return Promise.reject(error);
        //   }
        // },

    
        async fetchAnnonceById(id) {
          try {
            const response = await UserService.fetchAnnonceById(id);
            return Promise.resolve(response);
          } catch (error) {
            return Promise.reject(error);
          }
        },
        
        async deleteAnnoncesRequest({ commit }, id) {
            try {
              const response = await UserService.deleteAnnonces(id);
              commit('operationSuccess', response);
              return Promise.resolve(response);
            } catch (error) {
              commit('operationFailure', error);
              return Promise.reject(error);
            }
        },

        async postulerRequest({ commit }, { annonceId, formData }) {
            return UserService.postulerRecrutbyId(annonceId, formData).then(
              (response) => {
                commit("operationSuccess", response);
                return Promise.resolve(response);
              },
              (error) => {
                commit("operationFailure");
                return Promise.reject(error);
              }
            );
        },
        

        // async ressourcesFileRequest({ commit }, { resource_id, formData }) {
        //     return UserService.ressourcesGetFile(resource_id, formData).then(
        //       (response) => {
        //         commit("operationSuccess", response);
        //         return Promise.resolve(response);
        //       },
        //       (error) => {
        //         commit("operationFailure");
        //         return Promise.reject(error);
        //       }
        //     );
        // },

        
        // eslint-disable-next-line no-unused-vars
        async downloadResource({ commit }, { resource_id, formData }) {
            return UserService.downloadResource(resource_id, formData)
                .then(
                    (response) => {
                        commit("operationSuccess", response);
                        return Promise.resolve(response);
                    },
                    (error) => {
                        commit("operationFailure");
                        return Promise.reject(error);
                    }
                );
        },
        
        async formFormateurRequest({ commit }, data) {
            return UserService.formFormateur(data).then(
                (response) => {
                    commit("operationSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("operationFailure");
                    return Promise.reject(error);
                }
            );
        },
       
    },
    mutations: {
        operationSuccess(state, d) {
            state.data = d;
        },
        operationFailure(state) {
            state.data = [];
        },
    },
};