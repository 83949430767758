<template>
    <div class="Detail__sTORE">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbar />

          <div class="container-fluid bg-primary py-5 bg-header-formation" style="margin-bottom: 90px;">
              <div class="row py-5">
                  <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                      <router-link to="/agiles-store" class="h5 text-white m-2">Agiles Store</router-link>
                      <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                      <router-link to="/agiles-formations" class="h5 text-white m-2">Formations</router-link>
                  </div>
              </div>
          </div>

  
          <h1 class="mb-5" style="font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
            <center>AGILES FORMATIONS</center> 
          </h1>
          <center><h3 class="mb-5" style="color: #052E4A;">En savoir plus sur nos domaines de formations</h3> </center><br>


          <div class="container">
            <div class="row mt-5">

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/cloud.jpg') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Cloud Computing</h2>
                        <router-link to="" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/telfixe.jpg') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Telecom Mobile</h2>
                        <router-link to="" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/cyberse.jpeg') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Cyber Sécurité</h2>
                        <router-link to="" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/bigdata.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">IOT / Big Data</h2>
                        <router-link to="" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/project.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Project Management</h2>
                        <router-link to="" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>
               
            </div>    
          </div>   
      </div>
      <!-- content End -->
      
        <div class="row text-white m-0 p-5" 
          style="background-image: linear-gradient(to right, #F7A51B , #61C7F2);
           font-size: 23px !important; padding-top: 70px !important; padding-bottom: 70px !important;">
            <div class="col-md-6">
                Agiles Télécoms est là pour vous accompagner dans le
                renforcement de capacités professionnelles, afin de vous permettre d'être plus compétitifs et performant dans votre domaines d'activités.
            </div>

            <div class="col-md-6">
              <a class="p-3 btn btn-warning rounded" href="#open-modal" style="background-color: transparent !important; color: white;">FORMATEUR CANDIDATURE SPONTANNÉE</a>
            </div>
        </div>

        <!-- le modal --------------------------------------- -->
        <div> 
            <div id="open-modal" class="modal-window">
              <div class="Le__modal">
                <a href="#" title="Close" class="modal-close">X</a>

                <p style="color: #F7A51B;">REMPLISSER NOTRE FORMULAIRE POUR DEVENIR FORMATEUR CHEZ AGILES TELECOMS</p>

                <form @submit.prevent="submitFormFormateur">
                          <div class="row g-3">
                              <div class="col-md-6">
                                  <input type="text" class="form-control border-0 bg-light px-4" 
                                  placeholder="Nom" 
                                  style="height: 55px;"
                                  id="nom"
                                  v-model="nom"
                                  required
                                  />
                              </div>

                              <div class="col-md-6">
                                  <input type="text" class="form-control border-0 bg-light px-4" 
                                  placeholder="Prenoms" 
                                  id="prenoms"
                                  v-model="prenom"
                                  required
                                  style="height: 55px;" />
                              </div>

                              <div class="col-md-6">
                                  <input type="text" class="form-control border-0 bg-light px-4" 
                                  placeholder="Numéro de téléphone" 
                                  id="phone_number"
                                  v-model="phone"
                                  required
                                  style="height: 55px;" />
                              </div>

                              <div class="col-md-6">
                                  <input type="text" class="form-control border-0 bg-light px-4" 
                                  placeholder="Email" 
                                  id="email"
                                  v-model="email"
                                  required
                                  style="height: 55px;" />
                              </div>

                              <div class="col-12">
                                  <input type="text" class="form-control border-0 bg-light px-4" 
                                  placeholder="Domaine" 
                                  style="height: 55px;" id="domaine"
                                  v-model="domaine"
                                  required />
                              </div>

                              <div class="col-md-6">
                                  <input type="text" class="form-control border-0 bg-light px-4" 
                                  placeholder="Ville" 
                                  style="height: 55px;" id="ville"
                                  v-model="ville"
                                  required />
                              </div>

                              <div class="col-md-6">
                                  <input type="text" class="form-control border-0 bg-light px-4" 
                                  placeholder="Pays" 
                                  style="height: 55px;" id="pays"
                                  v-model="pays"
                                  required />
                              </div>

                              <div class="col-12">
                                  <button class="btn btn-primary w-100 py-3" type="submit">ENVOYEZ VOTRE MESSAGE</button>
                              </div>
                          </div>
                      </form>

              </div>
            </div>
          </div>
          <!-- le modal --------------------------------------- -->

  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbar from '@/components/NavNavbar.vue'
  import FooterView from '@/components/FooterView.vue'
  import Swal from "sweetalert2";

  
  export default {
    name: 'Detail__sTORE',
    components: {
      NavNavbar,
      FooterView
    },
    data() {
    return {
      loading: false,
      nom: "",
      prenom: "",
      email: "",
      phone: "",
      domaine: "",
      ville: "",
      pays: "",
      apiformateur: "",
    };
  },
  methods: {



async submitFormFormateur() {
  this.loading = true;
  var data = {
    nom: this.nom,
    prenom: this.prenom,
    email: this.email,
    phone: this.phone,
    domaine: this.domaine,
    ville: this.ville,
    pays: this.pays,
  };

  try {
    const response = await this.$store.dispatch("user/formFormateurRequest", data);

    this.loading = false;
    Swal.fire({
      title: 'Succès',
      text: `${response.message}`,
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    });

    // Réinitialisation des champs après le succès
    this.nom = "";
    this.prenom = "";
    this.email = "";
    this.phone = "";
    this.domaine = "";
    this.ville = "";
    this.pays = "";

  } catch (error) {
    this.loading = false;
    Swal.fire({
      title: 'Erreur',
      text: `${error.response.data.formateur} ${JSON.stringify(error.response.data.data)}`,
      icon: 'error',
      showConfirmButton: false,
      timer: 2000,
    });
  }
}


  },
  }
  </script>


  <style scoped>

    /* --bleu: #61C7F2;
    --orange: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E; */

    .bgg-img{
      background-color: #dddddd7b !important;
      background-repeat: repeat;
      height: 220px !important;
    }




.card {
  color: #F7A51B;
  background-size: cover;
  padding: 9rem 0 0;
  max-width: 35ch;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.5rem;
  overflow: hidden;
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(
    #00000000,
    #00000074 20%
  );
  transform: translateY(70%);
  transition: transform 500ms ease;
}

.card:hover .card-content {
  transform: translateY(0);
}

.card-title {
  font-size: 23px;
  position: relative;
  width: 250px;
}

.card-title::after {
  content: "";
  position: absolute;
  height: 4px;
  left: calc(var(--padding) * -1);
  bottom: 0;
  width: calc(100% + var(--padding));
  background: #F7A51B;
  transform: scaleX(0);
  transition: transform 500ms ease;
}

.card:hover .card-title::after {
  transform: scaleX(1);
}


.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: #13171ba5;
  background-color: transparent;
  padding: 0.5em 1.25em;
  border: 2px solid #F7A51B;
  border-radius: 0.25em;
}

.button:focus,
.button:hover {
  border: 2px solid #ffffff;
  background-color: #F7A51B !important;
}

/* ////// le modal */
.modal-window {
  position: fixed;
  background-color: rgba(170, 170, 170, 0.681) !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 55%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}

/* Demo Styles */


.modal-window > div {
  border-radius: 1rem;
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

small {
  color: lightgray;
}


  </style>
  

  