<template>
  <div class="DetailServices">

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

        <NavNavbarSI />



        <div class="container">
          <h1 class="mb-5" style="font-size: 35px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
            TELEPHONIE FIXE & MOBILE
          </h1>
        </div>   <br> 


          <div class="container p-5">
            <div class="mb-5 description" style="margin: 0px !important;">

            <div class="content">

              <div class="row">
                <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                  <h4 style="color: #61C7F2; font-size: 25px;">Téléphonie Fixe et Mobile</h4>
                </div>

                <div class="col-md-7" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                  Nous développons des applications Web et mobiles fournies sur des appareils Android à l'aide des meilleurs outils de développement d'applications Android, tels qu'Android Studio, Eclipse et IntelliJ.
                </div>
              </div>
              <hr style="height: 1px;">



              <div class="row">
                <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                  <h4 style="color: #61C7F2; font-size: 25px;">Téléphonie d'Entreprise</h4>
                </div>

                <div class="col-md-7" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                  Mise à la disposition des solutions d'appels internes et externes pour
                   les Entreprises afin de sécuriser leurs différents échanges.
                </div>
              </div>
              <hr style="height: 1px;">



              <div class="row">
                <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                  <h4 style="color: #61C7F2; font-size: 25px;">Audit sites et Informatiques Telecoms</h4>
                </div>

                <div class="col-md-7" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                  AGILES TELECOMS mène des audits des sites(réseaux) informatiques et 
                  Télécoms. Elle relève les différentes imperfections et propose des 
                  solutions afin de les optimiser.
                </div>
              </div>
              <hr style="height: 1px;">

            </div>

          </div>
        </div>

    </div>
    <!-- content End -->
    


    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import NavNavbarSI from '@/components/NavNavbarSI.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'DetailServices',
  components: {
    NavNavbarSI,
    FooterView
  }
}
</script>

<style scoped>
/* :root {
    --primary: #61C7F2;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */
</style>
