import axios from "axios";
import { baseURL } from "../../config/apiBase";

class UserService {
    async addRessources(formData) {
      const response = await axios.post(`${baseURL}uploadfile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    }

    contactUs(d) {
        return axios.post(baseURL + "contact", d).then((response) => {
            return response.data;
        });
    }

    formFormateur(d) {
        return axios.post(baseURL + "agiles-formateur", d).then((response) => {
            return response.data;
        });
    }

    candtSpontanee(d) {
        return axios.post(baseURL + "recrutement", d).then((response) => {
            return response.data;
        });
    }

    addAnnonces(data) {
        return axios.post(baseURL + 'faire-annonce', data)
          .then(response => {
            return response.data; 
          })
          .catch(error => {
            throw error;
          });
    }
    getAnnonce(id) {
        return axios.get(`${baseURL}annonces/${id}`);
    }



  // editAnnonces(id, data) {
  //   return axios.post(`${baseURL}annonce/update/${id}`, data)
  //     .then(response => {
  //       return response.data;
  //     })
  //     .catch(error => {
  //       throw error;
  //     });
  // }
    

    deleteAnnonces(id) {
        return axios.delete(`${baseURL}annonces/${id}`)
          .then(response => response.data)
          .catch(error => { throw error });
    }

    postulerRecrutbyId(offre_id, data) {
        return axios.post(`${baseURL}postuler/${offre_id}`, data).then((response) => {
          return response.data;
        }).catch((error) => {
          throw error;
        });
      }

    downloadResource(resource_id, data) {
        return axios.post(baseURL + `download-resource/${resource_id}`, data, {
            responseType: 'blob',
        }).then(response => {


            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');


            const contentType = response.headers['content-type'];
            if (contentType && contentType.toLowerCase().indexOf('application/json') !== -1) {
                return response.data.text().then(text => {
                    try {
                        const json = JSON.parse(text);
                        link.href = url;
                        link.download = json.link_ressoure;
                        document.body.appendChild(link);
                        link.click();

                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                        return json;
                    } catch (error) {
                        throw new Error('Failed to parse JSON response');
                    }
                });
            } else {
                throw new Error('Invalid response content type');
            }
        });
    }
}

export default new UserService();