import { createStore } from 'vuex'
import { user } from "./user.module";
import { staticdata } from "./staticdata.module";


const store = createStore({
  modules: {
      user,
      staticdata
  },
});

export default store;