<template>
    <div>
         <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0 shadow-lg sticky-top">
             <router-link to="/" class="navbar-brand p-0">
                 <img class="w-25" src="../assets/img/agiles-removebg-preview.png" alt="Image">
             </router-link>
             <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <font-awesome-icon icon="fa-solid fa-bars" />
             </button>
             <div class="collapse navbar-collapse" id="navbarCollapse">
                 <div class="navbar-nav navbar2 ms-auto py-0">
                    <router-link to="/" class="nav-item nav-link">Accueil</router-link>
                    <router-link to="/nos-services-et-produits" class="nav-item nav-link">Services</router-link>
                    <router-link to="/agiles-recrute" class="nav-item nav-link">Recrutements</router-link>
                    <router-link to="/agiles-activites" class="nav-item nav-link">Activités</router-link>
                    <router-link to="/agiles-store" class="nav-item nav-link">Stores</router-link>
                    <router-link to="/contactez-nous" class="nav-item nav-link">Contact</router-link>
                 </div>
             </div>
         </nav><br><br><br><br><br><br>
    </div>
 </template>