<template>
  <div class="DetailServices">

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

        <NavNavbarSI />

        

        <div class="container">
          <h1 class="mb-5" style="font-size: 35px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
            VALIDATION
          </h1>
        </div>    


          <div class="container p-5">
            <div class="mb-5 description" style="margin: 0px !important;">
            <div class="row mb-5">
              <div class="col-md-10 mb-3">
                <span style="font-size: 23px;"> Il s’agit d’une activité d’acceptance ou recette majeure pour tout type de projet.
                   Elle vise à assurer formellement que le produit ou le service est conforme aux spécifications de l’Entreprise. <br>
                <span style="color: #61C7F2; font-weight: 600;">AGILES TELECOMS</span> intervient sur plusieurs types de validations.</span>
              </div>
              <div style="color: #F7A51B; font-weight: 600; font-size: 20px;">
                <li>Vérification d’aptitude au bon fonctionnement(VABF)</li>
                <li>Vérification d'aptitude à la bonne exploitabilité(VABE)</li>
                <li>Vérification de service régulier (VSR)</li>
              </div>
            </div>


            <div class="content">
              <div class="row">
                <div class="card ValidCard">
                  Test d'exactitude des données
                </div>
                <div class="card ValidCard">
                  Test de transformation des données
                </div>
                <div class="card ValidCard">
                  Test de comparaison de bases de données
                </div>

                <div class="card ValidCard">
                  Test de bout en bout
                </div>
                <div class="card ValidCard">
                  Test d'exhaustivité des données
                </div>
                <div class="card ValidCard">
                  Test de la qualité des données
                </div>
                <div class="card ValidCard">
                  Test de comparaison de données
                </div>
              </div>
             
            </div>

          </div>
        </div>

    </div>
    <!-- content End -->
    


    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import NavNavbarSI from '@/components/NavNavbarSI.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'DetailServices',
  components: {
    NavNavbarSI,
    FooterView
  }
}
</script>

<style scoped>
/* :root {
    --primary: #61C7F2;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */

div .ValidCard{
  text-align: center;
  justify-content: center !important;
  height: 80px !important;
  width: auto !important;
  margin: 5px !important;
  border: 2px solid #61C7F2 !important;
  border-radius: 15px !important;
  padding: 10px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

div .ValidCol{
  text-align: center;
  justify-content: center !important;
}


</style>
