<template>
  <div class="container-fluid">
    <div class="row">

      <SidebarDash />

      <!-- Contenu Principal -->
      <div class="col">
        <main class="content">
          <button class="btn btn-dark d-md-none" @click="toggleSidebar">
            <i class="bi bi-list"></i>
          </button>
          <h6 class=" mt-5" style="color: #F7A51B;">----------LISTE DES CANDIDATURES FORMATEUR</h6>


            <!-- Liste Formateur card Start -->
            <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
              <div class="container py-5">

                <!-- Formateur list Start -->
                <section v-if="loadingGetFormateur">
                  <div class="row g-5">
                    <div class="col-12 text-center">
                      <h4>Chargement en cours ...</h4>
                    </div>
                  </div>
                </section>
        
                <section v-else>
                  <section v-if="formateur.length == 0">
                    <div class="row g-5">
                      <div class="col-12 text-center text-warning">
                        <h4>Aucune offre disponible</h4>
                      </div>
                    </div>
                  </section>

                  <section v-else>
                    <div class="row">
                      <div
                        class="col-md-4 wow slideInUp mb-4" data-wow-delay="0.1s"
                        v-for="index in displayedItems"
                        :key="index.id">

                        <div class="blog-item bg-light card rounded overflow-hidden" style="border: 2px solid #61C7F2; border-radius: 10% !important;">
                          <div class="blog-img card-header position-relative p-4" style="background: #FFFFFF !important">
                              <center>
                                <font-awesome-icon icon="fa-solid fa-user"  size="8x"/>
                              </center>
                          </div>
                          <div class="card-body">
                              <div class="d-flex mb-3">
                                  <small class="me-3" style="font-size: 20px !important">
                                      {{ index.nom }} {{ index.prenom }}</small>
                              </div>
                              <div class="d-flex mb-3">
                                  <small class="me-2"><font-awesome-icon icon="fa-solid fa-phone" /> {{ index.phone }}</small>
                                  <small><font-awesome-icon icon="fa-regular fa-envelope" />{{ index.email }}</small>
                              </div>
                              <div class="d-flex mb-3">
                                  <small class="me-3"><font-awesome-icon icon="fa-solid fa-layer-group" /> {{ index.domaine }}</small>
                              </div>
                              <div class="d-flex mb-3">
                                  <small class="me-3"><font-awesome-icon icon="fa-solid fa-location-dot" /> {{ index.ville }}</small>
                                  <small><font-awesome-icon icon="fa-solid fa-globe" /> {{ index.pays }}</small>
                              </div>
                          </div>
                        </div>

                      </div>
                    </div><br>
        
                    <!-- Liste Formateur pagination start -->
                    <div class="col-12 wow slideInUp mt-5" data-wow-delay="0.1s">
                      <nav aria-label="Page navigation">
                        <ul class="pagination pagination m-0">
                          <li
                            @click="goToPage(page - 1)"
                            :class="page == 1 ? 'page-item disabled' : 'page-item'"
                          >
                            <a
                              class="page-link rounded-0"
                              href="#"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true"
                                ><i class="bi bi-arrow-left"></i
                              ></span>
                            </a>
                          </li>
        
                          <li
                            v-for="p in totalPages"
                            :key="p"
                            @click="goToPage(p)"
                            :class="p == page ? 'page-item active' : 'page-item'"
                          >
                            <a class="page-link" href="#">{{ p }}</a>
                          </li>
        
                          <li
                            @click="goToPage(page + 1)"
                            :class="
                              page == totalPages ? 'disabled page-item' : 'page-item'
                            "
                          >
                            <a class="page-link rounded-0" href="#" aria-label="Next">
                              <span aria-hidden="true"
                                ><i class="bi bi-arrow-right"></i
                              ></span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <!-- Liste Formateur pagination end -->

                  </section>
                </section>
                
              </div>
            </div>
            <!-- Liste Formateur card End -->

            <!-- <div class="row">
              <div class="col-md-4">
                <div class="blog-item bg-light card rounded overflow-hidden" style="border: 2px solid #F7A51B; border-radius: 10% !important;">
              <div class="blog-img card-header position-relative p-4" style="background: #FFFFFF !important">
                  <center>
                    <font-awesome-icon icon="fa-solid fa-user"  size="8x"/>
                  </center>
              </div>
              <div class="card-body">
                  <div class="d-flex mb-3">
                      <small class="me-2" style="font-size: 20px !important">
                          kone lolo</small>
                  </div>
                  <div class="d-flex mb-3">
                      <small class="me-2"><font-awesome-icon icon="fa-solid fa-phone" /> 98765667788</small>
                      <small><font-awesome-icon icon="fa-regular fa-envelope" />Kouassibjjjjjjjebe@gmail.com</small>
                  </div>

                  

                  <div class="d-flex mb-3">
                                  <small class="me-3"><font-awesome-icon icon="fa-solid fa-layer-group" /> sdsqdsd</small>
                              </div>
                              <div class="d-flex mb-3">
                                  <small class="me-3"><font-awesome-icon icon="fa-solid fa-location-dot" /> qsdsqdsd</small>
                                  <small><font-awesome-icon icon="fa-solid fa-globe" /> dsqsqsq</small>
                              </div>
                  
              </div>
            </div>
              </div>
            </div> -->

        </main>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'


export default {
    name: "ListFormateur",
    components: {
      SidebarDash
    },
    data() {
      return {
        formateur: [],
        loading: false,
        loadingGetFormateur: false,
        selectedFile: null,
        nom: "",
        prenom: "",
        email: "",
        phone: "",
        domaine: "",
        pays:"",
        ville:"",
        page: 1,
        itemsPerPage: 6,
      };
    },

    mounted() {
      this.formateurList();
    },

    computed: {
      displayedItems() {
        const startIndex = (this.page - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.formateur.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.formateur.length / this.itemsPerPage);
      },
    },

    methods: {

      async formateurList() {
        this.loadingGetFormateur = true;
        await this.$store.dispatch("staticdata/fetchFormatPostuler").then(
          (response) => {
            this.formateur = response.data;
            this.loadingGetFormateur = false;
          },
          (error) => {
            console.log("error--- ", error);
            this.loadingGetFormateur = false;
          }
        );
      },

      goToPage(newPage) {
        if (newPage >= 1 && newPage <= this.totalPages) {
          this.page = newPage;
        }
      },
  }
};

</script>


<style scoped>
  .content {
    margin-left: 250px; /* Largeur du sidebar */
    padding: 20px;
  }

  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-250px);
    }

    .sidebar.show {
      transform: translateX(0);
    }

    .content {
      margin-left: 0;
    }
  }
</style>