<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />


        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>SESSION BOARDER CONTROLLER (SBC)</center>
                    </h1>

                    <div>
                        <span class="Annonce mb-4"> 
                            Toute la solution complète pour maintenir les liaisons SIP sécurisées et l'accès des utilisateurs <br>

                            Plateforme unifiée qui combine le SBC et le contrôleur multimédia               
                        </span>
                    </div>
                    

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items">
                            <span style="color: #61C7F2;"></span> 
                            SBC (Session Border Controller) est l'un des éléments clés du réseau IP destiné à protéger les réseaux internes basés sur l'architecture
                            NGN / IMS. PROTEI-SBC est un complexe matériel-logiciel conçu pour la gestion des accès des abonnés dans le réseau VoIP des opérateurs fixes
                            ou mobiles. Il peut garantir une protection efficace du cœur du réseau IP et fournir un large éventail de fonctionnalités supplémentaires 
                            requises au niveau de l'accès, notamment une gestion flexible du trafic, la redondance et la qualité de service. PROTEI-SBC exécute la 
                            fonctionnalité de contrôleur de frontière de session dans un réseau multiservice et achemine les appels VoIP des réseaux IP externes vers 
                            le cœur de commutation. <br> Il est basé sur les technologies informatiques actuelles et peut fonctionner avec tous les terminaux et appareils IP. <br><br>

                            PROTEI-SBC fournit en deux modifications - I-SBC (fonctionne entre les réseaux de télécommunications) et A-SBC (fonctions entre le réseau de l'opérateur et l'utilisateur final). <br><br>

                            I-SBC est un contrôleur de frontière de session qui fonctionne entre les réseaux. Il inclut les fonctions IBCF, TrGW (IBGF), IMS-ALG / IMS-AGW dans l'architecture IMS. <br><br>

                            A-SBC est un contrôleur de frontière de session qui fonctionne entre le réseau de l'opérateur et les utilisateurs finaux. Il inclut les fonctions P-CSCF, E-CSCF, IMS- ALG / IMS-AGW dans l'architecture IMS.
                        </p>
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li>Outil efficace pour réduire les coûts, l'espace et la consommation d'énergie</li>
                            <li>Prend en charge une large gamme de périphériques IP tiers</li>
                            <li>Système puissant d'interaction et prise en charge de la fonctionnalité de normalisation SIP</li>
                            <li>Prise en charge des mécanismes de routage pour une interconnexion transparente des réseaux</li>
                            <li>Protection de l'interface externe contre les attaques DoS, la fraude et l'interception via IP</li>
                            <li>Prise en charge du mode de sauvegarde</li>
                            <li>Mise à l'échelle horizontale</li>
                        </ul>
                        







                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
      NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>