<template>
    <div class="Services">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbar />
  

          <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel" style="height: 300px !important;">
            <div class="carousel-inner" style="height: 300px !important;">
                <div class="carousel-item active" style="max-height: 300px !important;">
                    <img class="w-100" src="../../assets/img/telecom-ac.jpeg" alt="Image">
                    <div class="carousel-caption d-flex flex-column align-items-center">
                        <div > <br><br><br><br>
                            <h1 class="display-6 text-white mb-md-4 animated zoomIn">
                                <span style="background-color: #61C7F2; font-size: 30px !important;">SERVICES TELECOMS</span> 
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" style="max-height: 300px !important;">
                    <img class="w-100" src="../../assets/img/energie-ac.jpeg" alt="Image">
                    <div class="carousel-caption d-flex flex-column align-items-center">
                        <div> <br><br><br><br>
                            <h1 class="display-6 text-white mb-md-4 animated zoomIn">
                                <span style="background-color: #F7A51B; font-size: 30px !important;">Energie</span> 
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" style="max-height: 300px !important;">
                    <img class="w-100" src="../../assets/img/service-it.jpeg" alt="Image">
                    <div class="carousel-caption d-flex flex-column align-items-center">
                        <div> <br><br><br><br>
                            <h1 class="display-6 text-white mb-md-4 animated zoomIn">
                                <span style="background-color: #F7A51B; font-size: 30px !important;">DEVELOPPEMENT D'APPLICATION</span> 
                            </h1>
                        </div>
                    </div>
                </div>
                
                
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
      </div>
      <!-- Navbar & Carousel End -->



      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">


        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
            <h5 class="fw-bold text-primary text-uppercase">Nos Services et produits</h5>
            <h1 class="mb-0">En savoir plus sur nos domaines de compétences.</h1>
        </div><br>

        <!-- Service Start -->
        <div class="content">
            
            <a class="card" style="background-color: transparent !important; border: none !important;" href="#!">
                <div class="front" 
                :style="{backgroundImage:'url(' +require('@/assets/img/devapk.jpg') +')',}">
                    <p>Développement d'application</p>
                </div>
                <div class="back">
                    <div class="liste">
                        <ul>
                            <li>Mobile</li>
                            <li>Web</li>
                            <li>Métier</li>
                            <li>Consulting</li>
                            <li>Ingenerie</li>
                        </ul>
                        <router-link to="/services-et-produits/developpement-application">
                            <center>
                                <button class="button">En savoir plus</button>
                            </center>
                        </router-link>
                    </div>
                </div>
            </a>

            <a class="card" style="background-color: transparent !important; border: none !important;" href="#!">
                <div class="front" 
                        :style="{
                            backgroundImage:
                            'url(' +
                            require('@/assets/img/telfixe.jpg') +
                            ')',
                        }"
                        >

                    <p>Téléphonie Fixe et Mobile</p>
                </div>
                <div class="back">
                    <div class="liste">
                        <ul>
                            <li>Technologies Mobiles (2g/3g/4g/IMS)</li>
                            <li>Téléphonies d'entreprise</li>
                            <li>Fttx</li>
                            <li>Audit Sites Télécoms</li>
                            <li>Maintenances</li>
                        </ul>
                        <router-link to="/details-services-et-produits/telephonie">
                            <center>
                                <button class="button">En savoir plus</button>
                            </center>
                        </router-link>
                    </div>
                </div>
            </a>

            <a class="card" style="background-color: transparent !important; border: none !important;" href="#!">
                <div class="front" 
                        :style="{
                            backgroundImage:
                            'url(' +
                            require('@/assets/img/roadmap.png') +
                            ')',
                        }"
                        >
                    <p>Prestations en ROADMAP (Managed Services)</p>
                </div>
                <div class="back">
                    <div class="liste">
                        <ul>
                            <li>Inspection de pylônes et d'équipements en hauteur par des drones</li>
                            <li>Système d'énergie éoliennes</li>
                            <li>Sécurité Informatique</li>
                            <li>Formations et Sécurité en Réalité Virtuelle (RV)</li>
                            <li>Surveillance et maintenance des pylônes par des capteurs</li>
                        </ul>

                        <router-link to="">
                            <center>
                                <button class="button">En savoir plus</button>
                            </center>
                        </router-link>
                    </div>
                </div>
            </a>

            <a class="card" style="background-color: transparent !important; border: none !important;" href="#!">
                <div class="front" 
                        :style="{
                            backgroundImage:
                            'url(' +
                            require('@/assets/img/energie-sc.png') +
                            ')',
                        }"
                        >


                    <p>Energie</p>
                </div>
                <div class="back">
                    <div class="liste">
                        <ul>
                            Nous proposons des solutions d'énergies solaires adaptées aux réseaux télécoms.
                        </ul>

                        <router-link to="">
                            <center>
                                <button class="button">En savoir plus</button>
                            </center>
                        </router-link>
                    </div>
                </div>
            </a>

            <a class="card" style="background-color: transparent !important; border: none !important;" href="#!">
                <div class="front" 
                        :style="{
                            backgroundImage:
                            'url(' +
                            require('@/assets/img/systinfo.jpg') +
                            ')',
                        }"
                        >


                    <p>Système d'Information</p>
                </div>
                <div class="back">
                    <div class="liste">
                        <ul>
                            <li>Cloud Computing</li>
                            <li>Virtualisation</li>
                            <li>Sécurité Informatique</li>
                            <li>Consulting</li>
                            <li>Administration Réseau & Système</li>
                        </ul>

                        <router-link to="/details-services-et-produits/systeme-d-information">
                            <center>
                                <button class="button">En savoir plus</button>
                            </center>
                        </router-link>
                    </div>
                </div>
            </a>

            <a class="card" style="background-color: transparent !important; border: none !important;" href="#!">
                <div class="front" 
                        :style="{
                            backgroundImage:
                            'url(' +
                            require('@/assets/img/validation.png') +
                            ')',
                        }"
                        >
                    <p>Test d'acceptation</p>
                </div>
                <div class="back">
                    <div class="liste">
                        <ul>
                            <li>VABF: Vérification d'aptitude au bon fonctionnement</li>
                            <li>VABE: Vérification d'aptitude à la bonne exploitabilité</li>
                            <li>VSR: Vérification de service régulier</li>
                        </ul>
                        <router-link to="/details-services-et-produits/validation">
                            <center>
                                <button class="button">En savoir plus</button>
                            </center>
                        </router-link>
                    </div>
                </div>
            </a>

            <a class="card" style="background-color: transparent !important; border: none !important;" href="#!">
                <div class="front" 
                
                        :style="{
                            backgroundImage:
                            'url(' +
                            require('@/assets/img/form_livre.jpeg') +
                            ')',
                        }"
                        >
                        
                    <p>Formation & Certification</p>
                </div>
                <div class="back">
                    <div class="liste">
                        <ul>
                            <li>Standards Telecoms mobile - 2G, 3G, 4G, 5G & IMS</li>
                            <li>Outils DevOps - vagant, Ansible, Teraform, etc.</li>
                            <li>Cloud & Orchestration - Openstack & Kebernetes</li>
                            <li>Virtualisation et Telco cloud</li>
                            <li>Sécurité des réseaux IP et Mobiles</li>
                        </ul>
                        <router-link to="/details-services-et-produits/formation">
                            <center>
                                <button class="button">En savoir plus</button>
                            </center>
                        </router-link>
                    </div>
                </div>
            </a>

            
            
        </div>
        <!-- Service end -->


      </div>

      
  
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbar from '@/components/NavNavbar.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'ServicesView',
    components: {
        NavNavbar,
      FooterView
    }
  }
  </script>

<style scoped>

/* :root {
    --primary: #61C7F2 #F7A51B;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */

.liste ul>li {
    list-style: circle !important;
    font-size: 18px !important;
}

.content {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
}

.card {
    color: inherit;
    cursor: pointer;
    width: calc(30% - 2rem);
    min-width: calc(30% - 2rem);
    height: 400px;
    min-height: 400px;
    perspective: 1000px;
    margin: 1rem;
    position: relative;
}
@media screen and (max-width: 800px) {
    .card {
        width: calc(50% - 2rem);
    }
}
@media screen and (max-width: 500px) {
    .card {
        width: 100%;
    }
}

.front {
    display: flex;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    color: white;
    text-align: bottom;
    align-items: end;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
}
.back {
    display: flex;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    color: white;
    text-align: left !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
}

.front {
    background-size: cover;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
    overflow: hidden;
}
.front:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(2, 2, 15);
    opacity: 0.55;
    z-index: -1;
}
.card:hover .front {
    transform: rotateY(180deg);
}
.card:nth-child(even):hover .front {
    transform: rotateY(-180deg);
}

.back {
    background-image: linear-gradient(to right, #F7A51B, #06A3DA) ;
    transform: rotateY(-180deg);
    padding: 0 1em;
}
.back .button {
    background: linear-gradient(135deg, #1a9be6, #1a57e6);
}
.back .button:before {
    box-shadow: 0 0 10px 10px rgba(26, 87, 230, 0.25);
    background-color: rgba(26, 87, 230, 0.25);
}
.card:hover .back {
    transform: rotateY(0deg);
}
.card:nth-child(even) .back {
    transform: rotateY(180deg);
}
.card:nth-child(even) .back .button {
    background: linear-gradient(135deg, #F7A51B, #f7a61bb3);
}
.card:nth-child(even) .back .button:before {
    box-shadow: 0 0 10px 10px rgba(230, 26, 60, 0.25);
    background-color: rgba(230, 26, 60, 0.25);
}
.card:nth-child(even):hover .back {
    transform: rotateY(0deg);
}

.button {
    transform: translateZ(40px);
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-weight: bold;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 5px;
    font: inherit;
    border: none;
    position: relative;
    transform-style: preserve-3d;
    transition: 300ms ease;
    
}
.button:before {
    transition: 300ms ease;
    position: absolute;
    display: block;
    content: "";
    transform: translateZ(-40px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    border-radius: 100px;
    left: 10px;
    top: 16px;
}
.button:hover {
    transform: translateZ(55px);
}
.button:hover:before {
    transform: translateZ(-55px);
}
.button:active {
    transform: translateZ(20px);
}
.button:active:before {
    transform: translateZ(-20px);
    top: 12px;
}









.myct {
  /* align-items: center; */
  display: flex;
  /* justify-content: center; */
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 300px !important;
  overflow: hidden;
  position: relative;
}
.slider::before, .slider::after, .myct::before, .myct::after {
  background: linear-gradient(to right, rgba(216, 173, 86, 0.036) 0%, rgba(133, 208, 249, 0.262) 100%);
  content: "";
  height: 300px;
  position: absolute;
  width: 1400px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
          animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

</style>

  