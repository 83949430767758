<template>
    <div class="container-fluid">
      <div class="row">
  
        <SidebarDash />
  
        <!-- Contenu Principal -->
        <div class="col">
            <main class="content">
  
                <h6 class="mb-3 mt-2" style="color: #F7A51B;">----------Liste des ressources</h6>

                <div class="">
                  <section v-if="loadingGetRessource">
                      <div class="row g-5">
                        <div class="col-12 text-center">
                          <h4>Affichage en cours ...</h4>
                        </div>
                      </div>
                  </section>
                
                  <div class="mt-5">

                    <section v-if="agileressource.length == 0">
                      <div class="row g-5">
                        <div class="col-12 text-center text-warning">
                          <h4>Aucune donnée disponible</h4>
                        </div>
                      </div>
                    </section>

                    <section v-else>

                      <div class="card mb-4" v-for="index in agileressource" :key="index.id">

                          <div class="card-header p-2">
                            <h3 class="text-center" style="font-weight: 600;">{{ index.title }}</h3>

                            <img v-bind:src="index.illustration" class="img-fluid mt-3 mb-5" alt=""> 

                            <div class="card-footer">
                              <a :href="index.fichier" target="_blank" style="color: #F7A51B; text-decoration: underline; text-transform: uppercase; font-weight: 900;">ICI Fichier à télécharger</a>
                            </div>

                          </div>

                      </div>

                    </section>
                      
                  </div>    
                </div>    
                        
            </main>
        </div>
        <!-- Contenu Principal -->
  
      </div>
    </div>
</template>
  


<script>
// @ is an alias to /src
import SidebarDash from '@/components/SidebarDash.vue'


export default {
  name: 'ListRessources',
  components: {
    SidebarDash
  },

  data() {
    return {
      agileressource: [],
      loading: false,
      selectedFile: null,
      loadingGetRessource: false,
      apiressource: "",
    };
  },

  mounted() {
    this.agileressourceList();
  },

  methods: {
    async agileressourceList() {
      this.loadingGetAnnonce = true;
      await this.$store.dispatch("staticdata/fetchRessources").then(
        (response) => {
          this.agileressource = response.data;
          this.loadingGetRessource = false;
        },
        (error) => {
          console.log("error--- ", error);
          this.loadingGetRessource = false;
        }
      );
    },

    handleFileChange(event) {
        this.selectedFile = event.target.files[0];
    },
  }

}
</script>
  
  
<style scoped>
  .content {
      margin-left: 250px; /* Largeur du sidebar */
      padding: 20px;
  }

  @media (max-width: 768px) {
  .sidebar {
      transform: translateX(-250px);
  }

  .sidebar.show {
      transform: translateX(0);
  }

  .content {
      margin-left: 0;
  }
  }

  .cadre {
    position: relative;

    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cadre.card {
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
</style>