import { createApp } from 'vue';
import App from './App.vue';
import "bootstrap";
import "v-wow";
import router from './router';


import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "../src/assets/lib/animate/animate.css";
import "../src/assets/lib/animate/animate.min.css";
import "../src/assets/lib/owlcarousel/assets/owl.carousel.css";
import "../src/assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "../src/assets/lib/owlcarousel/assets/owl.theme.default.css";
import "../src/assets/lib/owlcarousel/assets/owl.theme.default.min.css";
import "../src/assets/lib/owlcarousel/assets/owl.theme.green.css";
import "../src/assets/lib/owlcarousel/assets/owl.theme.green.min.css";


import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const app = createApp(App);

app.config.globalProperties.$swal = Swal;



/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faCubes } from '@fortawesome/free-solid-svg-icons'
import { faUsersGear } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons' 
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons' 
import store from './store' 


/* add icons to the library */
library.add(faCheck, faCubes, faUsersGear, faPhone, faAward, faArrowUp, faFacebookF, faLinkedinIn, faCircle, faEnvelope, faLocationDot, faBars, faUser, faGlobe, faCircleUser, faLayerGroup)




createApp(App).use(store)
.use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')