<template>
  
    <!-- écrit ici (début) -->

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

      <NavNavbar />

      <!-- Hero (Start) -->
      <div class="container-fluid bg-primary py-5 bg-header-contact">
          <div class="row py-5">
              <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                  <h1 class="display-4 text-white animated zoomIn">A propos de nous</h1>
                  <router-link to="/" class="h5 text-white m-2">Accueil</router-link> 
                  <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                  <router-link to="/a-propos-de-nous" class="h5 text-white m-2">A propos</router-link>
              </div>
          </div>
      </div>
      <!-- Hero (End) -->

    </div>
      <!-- Navbar & Carousel End -->
  
    <div class="bodi">
      
      <div class="container">

        <!-- Definir AGILES TELECOMS (start) -->
        <div class="row mb-4 container">
          <div class="col-lg-4 mb-3 mt-5">
            <img
              src="../../assets/img/agiles.jpeg"
              class="img-thumbnail p-5"
              alt=""
              style="background-color: transparent !important"
            />
          </div>

          <div class="col-lg-8 mt-5">
            <h2 class="mb-3" style="color: #61C7F2 !important">
              La meilleure solution informatique pour votre entreprise
            </h2><br>
            <div class="mb-5">
              <span style="color: #f7a51b">AGILES TELECOMS</span> est une société intervenant dans les domaines de l’informatiques, des télécoms et de l'Energies.
            <br> Nous accompagnons pleinement nos clients durant toutes les étapes de leurs projets, depuis l’étude initiale jusqu’à la mise en œuvre finale.
            </div>
            <div class="row g-0 mb-3 Abt">
                        <div class="col-sm-6 animated zoomIn">
                            <h5 class="mb-3">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                Le meilleur de l’industrie
                            </h5>
                            <h5 class="mb-3">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                Un personnel professionnel
                            </h5>
                        </div>
                        <div class="col-sm-6 animated zoomIn">
                            <h5 class="mb-3">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                A votre service
                            </h5>
                            
                            <h5 class="mb-3">
                                <font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" />
                                Primé
                            </h5>
                        </div>
            </div>

          </div>
        </div>
        <br />
        <!-- Definir AGILES TELECOMS (end) -->

  
        <!-- Pourquoi nous choisir (start) -->

        <div class="Title mt-5 mb-5">
          <div class="parent block1">Pourquoi nous choisir</div>
          <div class="parent block2 block2_1">Pourquoi nous choisir</div>
        </div><br><br>


        <div class="container-fluid">

            <div class="container">

                <!--first section start-->
                <div class="row align-items-center how-it-works mb-5">
                    <div class="col-2 text-center bottom">
                        <div class="circle">1</div>
                    </div>
                    <div class="col-8"><br>
                        <h5>Vous êtes unique ! Alors votre projet doit être unique. <br></h5>
                        <p>C’est pour cette raison que nous mettons à votre disposition un interlocuteur
                        unique qui va gérer à vos côtés l’ensemble de vos problématiques en vous mettant :</p>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Au cœur de nos préoccupations </span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Être à votre écoute  </span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Vous comprendre  </span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Accepter vos exigences  </span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Vous Accompagner  </span>
                    </div>
                </div>
                <!--first section end-->

                <!--second section start -->
                <div class="row align-items-center justify-content-end how-it-works mb-5">
                    <div class="col-8 text-right"><br>
                        <h5>Notre stratégie d’action s’adapte à vous.</h5>
                        <p>L’approche AGILE qui nous amène à concevoir notre métier différemment. Nous vous accompagnons depuis l’idée de digitalisation jusqu’à la réalisation de votre projet avec un suivi continu selon votre bon vouloir.</p>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Adapter à vous notre politique de prix très attrayante</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Rendre aussi détaillée que transparente la gestion de votre budget</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Vous fournir un rapport qualité prix imbattable</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Respecter les détails définis</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Gagner votre confiance à travers notre intégrité et notre dynamisme. </span>
                    </div>
                    <div class="col-2 text-center full">
                        <div class="circle">2</div>
                    </div>
                </div>
                <!--second section end -->

                <!--third section start-->
                <div class="row align-items-center how-it-works mb-5">
                    <div class="col-2 text-center top">
                        <div class="circle">3</div>
                    </div>
                    <div class="col-8"><br>
                        <h5>Notre Expérience est votre garantie et votre bénéfice.</h5>
                        <p>Fort de l’expérimentation, d’épreuves, de planification, d’acquisitions
                            de savoir et succès constituent notre moule, vous donnant l’assurance de bénéficier :</p>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> D’un professionnalisme remarquable </span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> D’une main d’œuvre cosmopolite et hautement qualifiée</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> D’un fort réseau de partenaires </span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> D’une prestation respectant les normes et standards internationaux</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> D’une solution à chacune de vos préoccupations quelles qu’elles soient.</span>
                    </div>
                </div>
                <!--third section end-->

                <!-- section four start-->
                <div class="row align-items-center justify-content-end how-it-works">
                    <div class="col-8 text-right"><br>
                        <h5>Nos métiers sont nos passions.</h5>
                        <p>Le plaisir que nous procure notre travail restera le centre de notre collaboration. Il aura pour effet de :</p>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Privilégier la relation client</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Rendre performante et sereine notre collaboration</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Mieux faire ressortir vos besoins</span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Rendre parfaits les moindres détails de votre projet </span><br>
                        <span class="choice_fa"><font-awesome-icon icon="check" size="1x" :style="{ color: '#61C7F2' }" /> Vous donner le sourire dans toutes les étapes de création de votre projet Adapter à vous notre politique de prix très attrayante.</span>
                    </div>
                    <div class="col-2 text-center full">
                        <div class="circle">4</div>
                    </div>
                </div> <br><br><br>
                <!-- section four start end-->

                <hr>
                <h6 class="text-center">@Restez Agiles</h6>

            </div> <!-- container timeline -->
            
        </div>

        
        <!-- Pourquoi nous choisir timeline (end) -->
  
       
      </div>
  </div>
    <!-- écrit ici (fin) -->
</template>
  
  <script>
  import NavNavbar from '@/components/NavNavbar.vue'
  
  export default {
    name: "AboutView",
    components: {
      NavNavbar,
    },
  };
  </script>
  
<style scoped>

p {
  font-size: 1em;
  line-height: 1.75em;
  border-top: 3px solid;
  -o-border-image: linear-gradient(to right, #61C7F2 0%, #61C7F2 100%);
     border-image: linear-gradient(to right, #F7A51B 0%, #61C7F2 100%);
  border-image-slice: 1;
  border-width: 3px;
  margin: 0;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 15px;
  counter-increment: section;
  position: relative;
  color: #34435E;
}


p:nth-child(odd) {
  border-right: 3px solid;
  padding-left: 0;
}
p:nth-child(odd):before {
  left: 100%;
  margin-left: -20px;
}

p:nth-child(even) {
  border-left: 3px solid;
  padding-right: 0;
}
p:nth-child(even):before {
  right: 100%;
  margin-right: -20px;
}

p:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

p:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.circle {
  font-weight: bold;
  border-radius: 50%;
  background-color: #f7a51b;
  color: #FFFFFF;
  height: 2em ;
  width: 2em;
  padding: 5px;
}

/* Timeline style end */

  
  .bodi {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #54595f;
    background-color: #b2d5f9c1;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .btn {
    font-weight: 700;
    transition: 0.1s;
    background-color: #f7a51b; 
    border: none;
    color: rgb(255, 255, 255);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  
  .btn:hover {
    -webkit-box-shadow: 0 8px 6px -6px #555555;
    -moz-box-shadow: 0 8px 6px -6px #555555;
    box-shadow: 0 8px 6px -6px #555555;
  }

  .bg-hero {
    background-size: contain;
    background-color: #61C7F2;
  }
  .bg-hero2 {
    background-size: contain;
    background-color: #61C7F2;
  }
  
  @media (max-width: 991.98px) {
    .bg-hero {
      background-size: cover;
    }
  }
  
  .about-start,
  .about-end {
    background-size: contain;
    background-color: #61C7F2;
  }
  
  @media (min-width: 992px) {
    .about-start {
      position: relative;
      margin-right: -90px;
      z-index: 1;
    }
  
    .about-end {
      position: relative;
      margin-left: -90px;
      z-index: 1;
    }
  }
  
  .img-border {
    position: relative;
    height: 100%;
    min-height: 400px;
  }
  
  .img-border::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 3rem;
    bottom: 3rem;
    border: 5px solid #f7a51b;
    border-radius: 6px;
  }
  
  .img-border img {
    position: absolute;
    top: 3rem;
    left: 3rem;
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);
    object-fit: cover;
    border-radius: 6px;
  }
  /* style about  */
  
  /*** Img Border ***/

.choice_fa{
    font-weight: 500; 
}  
  
  @-webkit-keyframes typing {
    from {
      width: 0;
    }
  }
  @-webkit-keyframes blink-caret {
    50% {
      border-color: transparent;
    }
  }
  
  
  

  
  div.parent {
    width: 100%;
    display: block;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .block1 {
    font-size: 60px;
    z-index: 1;
    color: #1b72a111;
    font-weight: 700 !important;
  }
  
  .block2 {
    font-size: 25px;
    color: #61C7F2 !important;
    z-index: 0;
    font-weight: 700 !important;
    margin-top: -60px;
  }
  
  /* width <= 412px */
  @media screen and (max-width: 412px) {
    .block2_2 {
      margin-top: -110px !important;
    }
    .block2_1 {
      margin-top: -240px !important;
    }
  }
  
  /* width >= 810px */
  @media screen and (min-width: 810px) {
    .block2_1 {
      margin-top: -100px !important;
    }
  }

  </style>