import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

//services link
import CatalogueView from '../views/services/CatalogueView.vue'
import ServicesView from '../views/services/ServicesView.vue'
import DevelApllication from '../views/services/servicespages/DevelApllication.vue'
import ValidationView from '../views/services/servicespages/ValidationView.vue'
import TelMobileView from '../views/services/servicespages/TelMobileView.vue'
import SystInfView from '../views/services/servicespages/SystInfView.vue'
import FormView from '../views/services/servicespages/FormView.vue'
import EnergieView from '../views/services/servicespages/EnergieView.vue'
import OpenSourceView from '../views/services/servicespages/OpenSourceView.vue'

import RecrutementView from '../views/recrutement/RecrutementView.vue'
import DetailrecrutView from '../views/recrutement/DetailrecrutView.vue'

//store pages link
import AgilesStoreView from '../views/store/AgilesStoreView.vue'

import AgilesformationView from '../views/store/agilesformation/AgilesformationView.vue'

import AgilesActivites from '../views/store/agilesact/AgilesActivites.vue'
import DetailActivites from '../views/store/agilesact/DetailActivites.vue'

import AgilesSolutions from '../views/store/agilessolut/AgilesSolutions.vue'
  //Items AS
  import ItemsOneView from '../views/store/agilessolut/itemsAS/ItemsOneView.vue'
  import ItemsTwoView from '../views/store/agilessolut/itemsAS/ItemsTwoView.vue'
  import ItemsThreeView from '../views/store/agilessolut/itemsAS/ItemsThreeView.vue'
  import ItemsFourView from '../views/store/agilessolut/itemsAS/ItemsFourView.vue'
  import ItemsFiveView from '../views/store/agilessolut/itemsAS/ItemsFiveView.vue'
  import ItemsSixView from '../views/store/agilessolut/itemsAS/ItemsSixView.vue'
  import ItemsSevenView from '../views/store/agilessolut/itemsAS/ItemsSevenView.vue'
  import ItemsHeightView from '../views/store/agilessolut/itemsAS/ItemsHeightView.vue'


//contact pages link
import ContactView from '../views/contact/ContactView.vue'
import AboutView from '../views/about/AboutView.vue'


//Admin pages
import ListFormateur from '../views/Admin/pages/candidats/ListFormateur'
import ListCdtAnnonces from '../views/Admin/pages/candidats/ListCdtAnnonces'
import ListCdtSpont from '../views/Admin/pages/candidats/ListCdtSpont'

import AddAnnonces from '../views/Admin/pages/annonces/AddAnnonces'
import ListAnnonces from '../views/Admin/pages/annonces/ListAnnonces'
import DetailAnnonces from '../views/Admin/pages/annonces/DetailAnnonces'
import EditAnnonces from '../views/Admin/pages/annonces/EditAnnonces' 

import AddRessources from '../views/Admin/pages/ressources/AddRessources' 
import ListRessources from '../views/Admin/pages/ressources/ListRessources' 
import VoirRessources from '../views/Admin/pages/ressources/VoirRessources' 


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  
  //Services link start
  {
    path: '/nos-services-et-produits',
    name: 'services',
    component: ServicesView
  },

  {
    path: '/notre-catalogue',
    name: 'catalogueview',
    component: CatalogueView
  },

  {
    path: '/services-et-produits/developpement-application',
    name: 'develApllication.vue',
    component: DevelApllication 
  },
  {
    path: '/details-services-et-produits/energie',
    name: 'energie',
    component: EnergieView
  },
  {
    path: '/details-services-et-produits/open-source',
    name: 'opensource',
    component: OpenSourceView
  },
  {
    path: '/details-services-et-produits/validation',
    name: 'validation',
    component: ValidationView 
  },
  {
    path: '/details-services-et-produits/telephonie',
    name: 'telmobile',
    component: TelMobileView
  },
  {
    path: '/details-services-et-produits/systeme-d-information',
    name: 'systeme',
    component: SystInfView
  },
  {
    path: '/details-services-et-produits/formation',
    name: 'formation',
    component: FormView
  },
  //Services link end-----------------------------------


  // Recrutement link start
  {
    path: '/agiles-recrute',
    name: 'recrutement',
    component: RecrutementView
  },
  {
    path: '/detail-annonces/:id',
    name: 'detailrecrutement',
    component: DetailrecrutView
  },
 // Recrutement link start---------------------------


  //Store links start
  {
    path: '/agiles-store',
    name: 'store',
    component: AgilesStoreView
  },
  {
    path: '/agiles-formations',
    name: 'Agilesformation',
    component: AgilesformationView
  },


  {
    path: '/agiles-activites',
    name: 'agilesActivites',
    component: AgilesActivites
  },
  {
    path: '/details-activites',
    name: 'detailActivites',
    component: DetailActivites
  },

  
  {
    path: '/agiles-solutions',
    name: 'agilesSolutions',
    component: AgilesSolutions
  },
    //AS items
    {
      path: '/agiles-solutions/local-number-for-visitor',
      name: 'Itemsoneview',
      component: ItemsOneView
    },
    {
      path: '/agiles-solutions/sessions-border',
      name: 'Itemstwoview',
      component: ItemsTwoView
    },
    {
      path: '/agiles-solutions/personal-ring-back-tone',
      name: 'Itemsthreeview',
      component: ItemsThreeView
    },
    {
      path: '/agiles-solutions/smart-city',
      name: 'Itemsfourview',
      component: ItemsFourView
    },
    {
      path: '/agiles-solutions/sms-firewall',
      name: 'Itemsfiveview',
      component: ItemsFiveView
    },
    {
      path: '/agiles-solutions/server-ussd',
      name: 'Itemssixview',
      component: ItemsSixView
    },
    {
      path: '/agiles-solutions/wifi-offload',
      name: 'Itemssevenview',
      component: ItemsSevenView
    },
    {
      path: '/agiles-solutions/ss7-firewall',
      name: 'Itemsheightview',
      component: ItemsHeightView
    },
  //Store link end-------------------------------


  //Contact link start
  {
    path: '/contactez-nous',
    name: 'contact',
    component: ContactView
  },
  //Contact link end----------------------------

  
  //About link start
  {
    path: '/a-propos-de-nous',
    name: 'about',
    component: AboutView
  },
  //About link end----------------------------


  //Admin links start//
  {
    path: '/lt-cdts-ft01',
    name: 'listformateur',
    component: ListFormateur
  },
  {
    path: '/lt-cdts-pt02',
    name: 'listcdtannonces',
    component: ListCdtAnnonces
  },

  {
    path: '/lt-cdts-spt03',
    name: 'listcdtspont',
    component: ListCdtSpont
  },
  
  {
    path: '/adof-01',
    name: 'addannonces',
    component: AddAnnonces
  },
  {
    path: '/ltof-02',
    name: 'listannonces',
    component: ListAnnonces
  },
  {
    path: '/ltof/details/:id',
    name: 'detailannonces',
    component: DetailAnnonces
  },
  {
    path: '/ltof/modifier/:id',
    name: 'editannonces',
    component: EditAnnonces,
    props: true 
  },

  {
    path: '/adsrc01',
    name: 'addressource',
    component: AddRessources
  },
  {
    path: '/adsrc-02',
    name: 'listressource',
    component: ListRessources
  },
  {
    path: '/adsrc/voir/:id',
    name: 'voirressources',
    component: VoirRessources
  },
  //Admin links end----------------------------


]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })


const router = createRouter({
  history: createWebHistory(),
  //mode: 'hash',
  routes,
});

export default router