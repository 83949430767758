<template>
  <div class="Contact">

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

        <NavNavbar />

        <div class="container-fluid bg-primary py-5 bg-header-contact" style="margin-bottom: 20px;">
            <div class="row py-5">
                <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 class="display-4 text-white animated zoomIn">Contactez-nous</h1>
                    <router-link to="/" class="h5 text-white m-2">Accueil</router-link> 
                    <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                    <router-link to="/contactez-nous" class="h5 text-white m-2">Contact</router-link>
                </div>
            </div>
        </div>

    </div>
    <!-- Navbar & Carousel End -->

     <!-- Contact Start -->
     <div class="container-fluid py-5 animated fadeInUp fad">
        <div class="container py-5">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
                <!-- <h5 class="fw-bold text-primary text-uppercase">Nous contacter</h5> -->
                <h1 class="mb-0">Si vous avez des questions, n'hésitez pas à nous contacter</h1>
            </div>
            
            <div class="row g-5 mb-5">
                <div class="col-lg-4">
                    <div class="d-flex align-items-center animated fadeIn">
                        <div class="bg_icon_border">
                            <div class="bg_icon_card d-flex align-items-center justify-content-center rounded">
                                <font-awesome-icon icon="fa-solid fa-phone" :style="{ color: '#ffffff' }" />
                            </div>
                        </div>
                        <div class="ps-4">
                            <h6 class="mb-2">N° de Téléphone</h6>
                            <h6 class="text-primary mb-0">+225 07 67 56 13 77</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-center animated fadeIn">
                        <div class="bg_icon_border">
                            <div class="bg_icon_card d-flex align-items-center justify-content-center rounded">
                                <font-awesome-icon icon="fa-regular fa-envelope" :style="{ color: '#ffffff' }" />
                            </div>
                        </div>
                        <div class="ps-4">
                            <h6 class="mb-2">E-mail</h6>
                            <h6 class="text-primary mb-0">info@agilestelecoms.com</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-center animated fadeIn">
                        <div class="bg_icon_border">
                            <div class="bg_icon_card d-flex align-items-center justify-content-center rounded">
                                <font-awesome-icon icon="fa-solid fa-location-dot" :style="{ color: '#ffffff' }" />
                            </div>
                        </div>
                        <div class="ps-4">
                            <h6 class="mb-2">Adresse</h6>
                            <h6 class="text-primary mb-0">8ème Tranche, CNPS</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row g-5 mt-2">
                <div class="col-lg-6 animated slideInUp slid">
                    <form @submit.prevent="submitContactUsForm">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <input type="text" class="form-control border-0 bg-light px-4" 
                                placeholder="Nom" 
                                style="height: 55px;"
                                id="nom"
                                v-model="nom"
                                required
                                />
                            </div>

                            <div class="col-md-6">
                                <input type="text" class="form-control border-0 bg-light px-4" 
                                placeholder="Prenoms" 
                                id="prenoms"
                                v-model="prenom"
                                required
                                style="height: 55px;" />
                            </div>

                            <div class="col-md-6">
                                <input type="text" class="form-control border-0 bg-light px-4" 
                                placeholder="Numéro de téléphone" 
                                id="phone_number"
                                v-model="phone"
                                required
                                style="height: 55px;" />
                            </div>

                            <div class="col-md-6">
                                <input type="text" class="form-control border-0 bg-light px-4" 
                                placeholder="Email" 
                                id="email"
                                v-model="email"
                                required
                                style="height: 55px;" />
                            </div>

                            <div class="col-12">
                                <input type="text" class="form-control border-0 bg-light px-4" 
                                placeholder="Sujet" 
                                style="height: 55px;" id="object"
                                v-model="object"
                                required />
                            </div>

                            <div class="col-12">
                                <textarea class="form-control border-0 bg-light px-4 py-3" rows="4" 
                                placeholder="Message" id="message" 
                                style="height: 100px" 
                                v-model="message" 
                                required></textarea>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-primary w-100 py-3" type="submit">ENVOYEZ VOTRE MESSAGE</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-6 animated slideInUp slid map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.1567090965505!2d-3.9789706259956836!3d5.39307633526855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1931f5ace0f7f%3A0x2c29656ca95aab4c!2sAGILES%20TELECOMS!5e0!3m2!1sfr!2sci!4v1683110595508!5m2!1sfr!2sci" width="250" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
    

        </div>
    </div>
    <!-- Contact End -->


    <FooterView />
  </div>
</template>

<script>
  // @ is an alias to /src
  import NavNavbar from '@/components/NavNavbar.vue'
  import FooterView from '@/components/FooterView.vue'
  import Swal from "sweetalert2";


  export default {
    name: 'HomeView',
    components: {
      NavNavbar,
      FooterView
    },
    data() {
    return {
      loading: false,
      nom: "",
      prenom: "",
      email: "",
      phone: "",
      object: "",
      message: "",
      apimessage: "",
    };
  },
  methods: {
    async submitContactUsForm() {
      this.loading = true;
      var data = {
        nom: this.nom,
        prenom: this.prenom,
        email: this.email,
        phone: this.phone,
        object: this.object,
        message: this.message,
      };
      console.log(data, " data");
      await this.$store.dispatch("user/contactUsRequest", data).then(
        (response) => {
          this.loading = false;
          this.toast.success(response.message, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        (error) => {
          this.loading = false;
          this.apimessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;

          Swal.fire({
            title: "Oups :)",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message +
                  " " +
                  JSON.stringify(error.response.data.data)) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
    },
  },
  }
</script>

<style scoped>
/* :root {
    --primary: #61C7F2;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */

h6{
    color: #61C7F2 !important;
}
.text-primary{
    color: #F7A51B !important;
}
.bg_icon_card{
    background-color: #F7A51B !important;
    width: 40px; height: 40px;
}
.bg_icon_border{
    padding: 2px !important;
    border:1px solid #F7A51B !important;
}

.slid{
    animation-duration: 0.3s;
}
.fad{
    animation-duration: 0.1s;
}
.fad div{
    animation-duration: 3s;
}
.map-responsive{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
.map-responsive iframe{
    left: 0;
    top: 0;
    height: 75%;
    width: 100%;
    position: absolute;
}















/* 
*{
    margin: 0;
    padding: 0;
}

.signature {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.signature a {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #0cb9d8;
    text-decoration: none;
}

.box-area {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    width: 70px;
    height: 400px;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    transition: all linear 0.6s;
}

.box:last-child{
    margin-right: 0;
}

.box::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.box i{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background: #fff;
    position: absolute;
    bottom: 20px;
    left: 10px;
    font-size: 20px;
}

.box:nth-child(1) i{
    color: #ff5200;
}
.box:nth-child(2) i{
    color: #ca470a;
}
.box:nth-child(3) i{
    color: #00d9ff;
}
.box:nth-child(4) i{
    color: #0077ff;
}

.box h2 {
    position: absolute;
    bottom: 30px;
    left: 60px;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    color: #f6f6f6;
    font-weight: 600;
    opacity: 0;
    transition: all linear 0.3s;
}

.box p {
    position: absolute;
    bottom: 10px;
    left: 60px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #f6f6f6;
    opacity: 0;
    transition: all linear 0.3s;
}

.box.active h2{
    opacity: 1;
}

.box.active p{
    opacity: 1;
}

.active{
    width: 500px;
    transition: all linear 0.6s;
} */
</style>
