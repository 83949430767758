<template>
    <div class="Agiles__sTORE">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbar />

            <div class="container-fluid bg-primary py-5 bg-header-store" style="margin-bottom: 90px;">
                <div class="row py-5">
                    <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                        <router-link to="/" class="h5 text-white m-2">Accueil</router-link>
                        <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                        <router-link to="/agiles-store" class="h5 text-white m-2">Agiles Store</router-link>
                    </div>
                </div>
            </div>
  
      </div>
      <!-- Navbar & Carousel End -->
      
      <h1 class="mb-5" style="font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
        <center> AGILES STORE</center> 
      </h1>
      <center><h3 style="color: #052E4A;">En savoir plus sur Agiles Telecoms</h3> </center>
  

    <!-- recrutement card Start -->
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">

            <div class="row g-5">
                <div class="col-md-4">
                    <router-link to="/agiles-formations">
                        <div class="wrapper">
                            <div class="card"
                                :style="{
                                backgroundImage:
                                'url(' +
                                require('@/assets/img/agiles_formt.jpeg') +
                                ')',
                            }">
                                <div class="content">
                                    <h3>Agiles Formations</h3>
                                    <div class="location">
                                        <p class='location-name'>Parcourir</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                    
                <div class="col-md-4">
                    <router-link to="/agiles-solutions">
                        <div class="wrapper">
                                <div class="card" data-tilt 
                                :style="{
                                backgroundImage:
                                'url(' +
                                require('@/assets/img/solu.jpeg') +
                                ')',
                            }">
                                <div class="content">
                                    <h3>Agiles Solutions</h3>
                                    <div class="location">
                                        <p class='location-name'>Parcourir</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                    
                <div class="col-md-4">
                    <router-link to="/agiles-activites">
                        <div class="wrapper">
                            <div class="card" data-tilt 
                                :style="{
                                backgroundImage:
                                'url(' +
                                require('@/assets/img/activite.jpeg') +
                                ')',
                            }">
                                <div class="content">
                                    <h3>Agiles Activités</h3>
                                    <div class="location">
                                        <p class='location-name'>Parcourir</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            
        </div>
    </div>
    <!-- recrutement card End -->



      <FooterView />
    </div>
  </template>
  
  <script>
  import NavNavbar from '@/components/NavNavbar.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles__sTORE',
    components: {
      NavNavbar,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .card {
        background-size: 100%;
        background: no-repeat;
        padding: 10px;
        cursor: pointer;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        min-height: 430px;
        box-shadow: 0px 0px 0px 0px rgba(4, 26, 47, 0.06),
            0px 3px 6px 0px rgba(4, 26, 47, 0.06), 0px 12px 12px 0px rgba(4, 26, 47, 0.05),
            0px 26px 16px 0px rgba(4, 26, 47, 0.03),
            0px 47px 19px 0px rgba(4, 26, 47, 0.01), 0px 74px 21px 0px rgba(4, 26, 47, 0);
        transform-style: preserve-3d;
    }

    .content {
        background: white;
        padding: 16px 24px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        transform-style: preserve-3d;
        transform: translatez(20px);
    }

    .location-name {
        color: #F7A51B;
        font-size: 1rem;
        font-weight: 400;
        line-height: 16px;
    }

  </style>