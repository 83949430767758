<template>
  <div class="container-fluid">
    <div class="row">
      <SidebarDash />
      <div class="col">
        <main class="content">
          <h6 class="mb-3 mt-2" style="color: #F7A51B;">----------Editer UNE ANNONCE</h6>
          <div class="cadre">
            <div class="card p-3" style="width: 45rem;">
              <form ref="form" @submit.prevent="submitEditAnnoncesForm">
                <div class="mb-3">
                  <label for="titre" class="form-label">Titre de l'annonce</label>
                  <input type="text" class="form-control" v-model="uptitle" id="titre">
                </div>
                <div class="mb-3">
                  <label for="entrepris" class="form-label">Entreprise</label>
                  <input type="text" class="form-control" id="entrepris" v-model="upentreprise">
                </div>
                <div class="mb-3">
                  <label for="diplome" class="form-label">Diplome</label>
                  <input type="text" class="form-control" id="diplome" v-model="updiplome">
                </div>
                <div class="mb-3">
                  <label for="dure" class="form-label">Durée d'expérience</label>
                  <input type="text" class="form-control" id="dure" v-model="updure_experience">
                </div>
                <div class="mb-3">
                  <label for="competence" class="form-label">Compétences techniques</label>
                  <textarea class="form-control" id="competence" v-model="upcomp_tech" rows="3"></textarea>
                </div>
                <div class="mb-3">
                  <label for="aptitude" class="form-label">Aptitudes professionnels</label>
                  <textarea class="form-control" id="aptitude" v-model="upaptitude_pro" rows="3"></textarea>
                </div>
                <div class="mb-3">
                  <label for="description" class="form-label">Description de l'annonce</label>
                  <textarea class="form-control" id="description" v-model="updescription_annonce" rows="3"></textarea>
                </div>
                <button type="submit" class="btn btn-outline-warning w-100" style="background-color: #F7A51B; color: white;">Mettre à jour</button>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'
import Swal from "sweetalert2";

export default {
  name: "EditAnnonces",
  components: { SidebarDash },
  data() {
    return {
      annonce: null,
            annonces: [],
      loading: false,
      // annonce: null,
      title: "",
      entreprise: "",
      diplome: "",
      dure_experience: "",
      comp_tech: "",
      aptitude_pro: "",
      description_annonce: "",
      uptitle: "",
      upentreprise: "",
      updiplome: "",
      updure_experience: "",
      upcomp_tech: "",
      upaptitude_pro: "",
      updescription_annonce: "",
    };
  },
  mounted() {
    this.getDetAnnonces();
    this.getDetAnnonces(this.$route.params.id);

  },
  methods: {
    // async fetchAnnonce() {
    //   try {
    //     const annonces = await this.$store.dispatch("user/fetchAnnonceById", this.annonceId);
    //     this.title = annonces.title;
    //     this.entreprise = annonces.entreprise;
    //     this.diplome = annonces.diplome;
    //     this.dure_experience = annonces.dure_experience;
    //     this.comp_tech = annonces.comp_tech;
    //     this.aptitude_pro = annonces.aptitude_pro;
    //     this.description_annonce = annonces.description_annonce;
    //   } catch (error) {
    //     Swal.fire({
    //       title: "Erreur",
    //       text: "Impossible de récupérer les données de l'annonce",
    //       icon: "error",
    //     });
    //   }
    // },

    async getDetAnnonces(id) {
            this.isLoading = true;
            await this.$store.dispatch("staticdata/getDetailAnnonces", id)
            .then(
                (response) => {
                    this.isLoading = false;
                    this.annonce = response.data;
                },
                (error) => {
                    this.isLoading = false;
                    console.log("error--- ", error);
                }
            );
        },
    async submitEditAnnoncesForm() {
      var annonceFetch = {
        title: this.uptitle,
        entreprise: this.upentreprise,
        diplome: this.updiplome,
        dure_experience: this.updure_experience,
        comp_tech: this.upcomp_tech,
        aptitude_pro: this.upaptitude_pro,
        description_annonce: this.updescription_annonce,
        date_modification: new Date().toISOString().split('T')[0], // Ajout de la date de modification
      };
      await this.$store.dispatch("user/editAnnoncesRequest", annonceFetch).then(
        (response) => {
          console.log(" --- ", response);
          this.toast.success(response.message, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;

          Swal.fire({
            title: "Oups :) une erreur s'est produite",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message +
                  " " +
                  JSON.stringify(error.response.data.data)) ||
              error.data ||
              error.message ||
              error.toString(),
            icon: "error",
          });
        }
      );
      await this.getDetAnnonces();


      // try {
      //   const formData = {
      //     title: this.title,
      //     entreprise: this.entreprise,
      //     diplome: this.diplome,
      //     dure_experience: this.dure_experience,
      //     comp_tech: this.comp_tech,
      //     aptitude_pro: this.aptitude_pro,
      //     description_annonce: this.description_annonce,
      //     date_modification: new Date().toISOString().split('T')[0], // Ajout de la date de modification
      //   };
      //   await this.$store.dispatch("user/editAnnoncesRequest", { id: this.annonceId, formData });
      //   Swal.fire({
      //     title: "Succès",
      //     text: "L'annonce a été modifiée avec succès",
      //     icon: "success",
      //   });
      // } catch (error) {
      //   Swal.fire({
      //     title: "Erreur",
      //     text: "Impossible de modifier l'annonce",
      //     icon: "error",
      //   });
      // }
    },
  },
  created() {
    this.annonceId = this.$route.params.id;
    this.getDetAnnonces(this.$route.params.id);
    this.getDetAnnonces();
  },
};
</script>

<style scoped>
/* Styles identiques à ceux du fichier précédent */
.content {
  margin-left: 250px; /* Largeur du sidebar */
  padding: 20px;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-250px);
  }
  .sidebar.show {
    transform: translateX(0);
  }
  .content {
    margin-left: 0;
  }
}

.cadre {
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cadre.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}
</style>
