<template>
    <div class="container-fluid">
      <div class="row">
  
        <SidebarDash />
  
        <!-- Contenu Principal -->
        <div class="col">
            <main class="content">

                <h6 class="mb-5 mt-5" style="color: #F7A51B;">----------DÉTAILS DE L'ANNONCE</h6>
                

                <div class="container">

                    <div v-if="annonce" class="wow fadeInUp" data-wow-delay="0.1s">
                        <div>
                            <span style="color: #052E4A; font-weight: 700;">POSTE : </span> 
                            <span class="Titre__poste mb-4"> {{ annonce.title }} </span><br><br>
                        </div><br>


                            <h3 class="Titre">- Description du profil</h3><br>

                            <h6 class="Sous__titre">Diplômes requis</h6>
                                <div v-html="annonce.diplome">
                                </div>  <br><br>
                                    
                            <h6 class="Sous__titre">Durée d'expérience</h6>
                                <div v-html="annonce.dure_experience"></div> <br><br>

                            <div class="desc__competences">
                                <h3 class="Titre">- Compétences réquises</h3><br>

                                <h6 class="Sous__titre">Compétences techniques</h6>
                                <div v-html="annonce.comp_tech"></div> <br><br>

                                <h6 class="Sous__titre">Aptitudes professionnelles</h6>
                                <div v-html="annonce.aptitude_pro"></div> <br><br>
                            </div>
                            
                    </div>
                </div>

            </main>
        </div>

      </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'
  
  export default {
    name: "DetailAnnonces",
    components: {
        SidebarDash
    },

    data() {
        return {
            annonce: null,
            annonces: [],
            isLoading: false,
            selectedFile: null,
            postuler: null,
            nom: "",
            prenom: "",
            email: "",
            phone: "",
            motivation: "",
            image: "",
            apipostuler: "",
        };
    },

        
    mounted(){
        this.getDetAnnonces(this.$route.params.id);
    },

    methods: {
        async getDetAnnonces(id) {
            this.isLoading = true;
            await this.$store.dispatch("staticdata/getDetailAnnonces", id)
            .then(
                (response) => {
                    this.isLoading = false;
                    this.annonce = response.data;
                },
                (error) => {
                    this.isLoading = false;
                    console.log("error--- ", error);
                }
            );
        },

      goToPage(newPage) {
        if (newPage >= 1 && newPage <= this.totalPages) {
          this.page = newPage;
        }
      }
    }
 };

</script>

<style scoped>
.Titre{
    color: #61C7F2;
}
    .content {
        margin-left: 250px; /* Largeur du sidebar */
        padding: 20px;
    }

    @media (max-width: 768px) {
    .sidebar {
        transform: translateX(-250px);
    }

    .sidebar.show {
        transform: translateX(0);
    }

    .content {
        margin-left: 0;
    }
    }
</style>
