<template>
    <div class="container-fluid">
      <div class="row">
  
        <SidebarDash />
  
        <!-- Contenu Principal -->
        <div class="col">
            <main class="content">

                <h6 class="mb-3 mt-2" style="color: #F7A51B;">----------AJOUTER UNE ANNONCE</h6>

                <div class="cadre">
                    <div class="card p-3" style="width: 45rem;" >


                        <form ref="form" @submit.prevent="submitaddAnnoncesForm">
                            <div class="mb-3">
                                <label for="titre" class="form-label">Titre de l'annonce</label>
                                <input type="text" class="form-control" v-model="title" id="titre">
                            </div>
                            <div class="mb-3">
                                <label for="entrepris" class="form-label">Entreprise</label>
                                <input type="text" class="form-control" id="entrepris" v-model="entreprise">
                            </div>
                            <div class="mb-3">
                                <label for="diplome" class="form-label">Diplome</label>
                                <input type="text" class="form-control" id="diplome" v-model="diplome">
                            </div>
                            <div class="mb-3">
                                <label for="dure" class="form-label">Durée d'expérience</label>
                                <input type="text" class="form-control" id="dure" v-model="dure_experience" >
                            </div>
                            <div class="mb-3">
                                <label for="competence" class="form-label">Compétences techniques</label>
                                <textarea class="form-control" id="competence" v-model="comp_tech" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="aptitude" class="form-label">Aptitudes professionnels</label>
                                <textarea class="form-control" id="aptitude" v-model="aptitude_pro" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="description" class="form-label">Description de l'annonce</label>
                                <textarea class="form-control" id="description" v-model="description_annonce" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="image" class="form-label">Ajouter une image</label>
                                <input 
                                    class="form-control"
                                    type="file"
                                    ref="file"
                                    id="file"
                                    @change="handleFileChange"
                                    accept=".png, .jpg, .jpeg"
                                    required
                                >
                            </div>
                            <button type="submit" class="btn btn-outline-warning w-100" style="background-color: #F7A51B; color: white;">Enregistrer</button>
                        </form>

                        
                    </div>
                </div>

            </main>
        </div>
        <!-- Contenu Principal end-->

      </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'
import Swal from "sweetalert2";


export default {
    name: "AddAnnonces",
    components: {
        SidebarDash
    },

    data() {
        return {
            isLoading: false,
            selectedFile: null,
            title: "",
            entreprise: "",
            diplome: "",
            dure_experience: "",
            comp_tech: "",
            aptitude_pro: "",
            description_annonce: "",
            image: "",
            apifaireannonce: "",
        };
    },

    methods: {

        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },

        async submitaddAnnoncesForm() {
        this.loading = true;
  
        if (this.selectedFile) {
          let formData = new FormData();
          formData.append("fichiers", this.selectedFile);
          formData.append("title", this.title);
          formData.append("entreprise", this.entreprise);
          formData.append("diplome", this.diplome);
          formData.append("dure_experience", this.dure_experience);
          formData.append("comp_tech", this.comp_tech);
          formData.append("aptitude_pro", this.aptitude_pro);
          formData.append("description_annonce", this.description_annonce);
  
          await this.$store.dispatch("user/addAnnoncesRequest", formData).then(
            (response) => {
              this.loading = false;
              this.$refs.form.reset();
              this.title = this.entreprise = this.diplome = this.dure_experience = this.comp_tech =this.aptitude_pro = this.description_annonce="";
              const message =
                response.title +
                " " +
                response.entreprise +
                ", L'annonces a été enregistré avec succès!";
              Swal.fire({
                title: "Félicitation",
                text: message,
                icon: "success",
              });
            },
            (error) => {
              this.loading = false;
              this.apifaireannonce =
                (error.response &&
                  error.response.data &&
                  error.response.data.faireannonce) ||
                error.faireannonce ||
                error.toString();
              this.loading = false;
  
              Swal.fire({
                title: "Oups :)",
                text:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message +
                      " " +
                      JSON.stringify(error.response.data.errors)) ||
                  error.data ||
                  error.faireannonce ||
                  error.toString(),
                icon: "error",
              });
            }
          );
        } else {
          Swal.fire({
            title: "Oups :)",
            text: "Echec de validation du formulaire, vérifiez que vous avez chargé un fichier.",
            icon: "error",
          });
        }
      },

    }
}
</script>

<style scoped>
    .content {
        margin-left: 250px; /* Largeur du sidebar */
        padding: 20px;
    }

    @media (max-width: 768px) {
    .sidebar {
        transform: translateX(-250px);
    }

    .sidebar.show {
        transform: translateX(0);
    }

    .content {
        margin-left: 0;
    }
    }

    .cadre {
      position: relative;

      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cadre.card {
      
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      background-color: #fff;
    }
</style>