<template>
    <div>
        <!-- Footer Start -->
        <div class="container-fluid bg-dark text-light mt-5 animated fadeInUp fadeInU">
            <div class="container">
                <div class="row gx-5">
                    <div class="col-lg-6 col-md-6 footer-about">
                        <div class="d-flex flex-column align-items-center justify-content-center text-center h-100  p-4">
                            <router-link to="/" class="navbar-brand">
                                <h3 class="m-0 text-white">Nos partenaires</h3>
                            </router-link>

                            <!-- <div class="container p-5 bg-white">
                                <section class="nos-partenaires">
                                    <div class="row">
                                        <div class="img-thumbnail col-md-3"><img src="../assets/img/logo-orange.png" width="70"></div>
                                        <div class="img-thumbnail col-md-3"><img src="../assets/img/logo-3tech.png" width="70"></div>
                                        <div class="img-thumbnail col-md-3"><img src="../assets/img/logo-owleye.png" width="70"></div>
                                        <div class="img-thumbnail col-md-3 pt-3"><img src="../assets/img/lion-rock.png" width="70"></div>
                                    </div>

                                    <div class="row">
                                        <div class="img-thumbnail col-md-4"><img src="../assets/img/logo-ulefone.png" width="70"></div>
                                    <div class="img-thumbnail col-md-4 pt-4"><img src="../assets/img/logo-proteipi.png" width="70"></div>
                                    <div class="img-thumbnail col-md-4 pt-3"><img src="../assets/img/Moov_Africa_logo.png" width="70"></div>
                                    </div>
                                </section>
                            </div> -->

                            <div class="brands p-5 bg-white">
                                <div class="brand mb-2"><img src="../assets/img/logo-orange.png" width="70"/></div>
                                <div class="brand mb-2"><img src="../assets/img/logo-3tech.png" width="75"/></div>
                                <div class="brand mb-2"><img src="../assets/img/logo-owleye.png" width="75"/></div>
                                <div class="brand mb-2"><img src="../assets/img/lion-rock.png" width="80"/></div>
                                <div class="brand mb-2"><img src="../assets/img/logo-ulefone.png" width="70"/></div>
                                <div class="brand mb-2"><img src="../assets/img/logo-proteipi.png" width="80"/></div>
                                <div class="brand mb-2"><img src="../assets/img/Moov_Africa_logo.png" width="80"/></div>
                            </div>


                            
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="row gx-5">

                            <div class="col-lg-6 col-md-12 pt-5 mb-5">
                                <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 class="text-light mb-0">Infos contact</h3>
                                </div>
                                <div class="d-flex mb-2">
                                    <i class="bi bi-telephone text-primary me-2"></i>
                                    <p class="mb-0">+225 07 67 56 13 77</p>
                                </div>
                                <div class="d-flex mb-2">
                                    <i class="bi bi-envelope-open text-primary me-2"></i>
                                    <p class="mb-0">info@agilestelecoms.com</p>
                                </div>
                                <div class="d-flex mb-2">
                                    <i class="bi bi-geo-alt text-primary me-2"></i>
                                    <p class="mb-0">Angré 8ème Tranche, Abidjan</p>
                                </div>

                                <div class="d-flex mt-4">
                                    <a class="btn btn-primary btn-square me-2" href="https://www.facebook.com/agilestelecoms" target="_blank">
                                        
                                        <font-awesome-icon icon="fa-brands fa-facebook-f" size="1x" :style="{ color: '#ffffff' }" />
                                        
                                    </a>
                                    <a class="btn btn-primary btn-square me-2" href="https://www.linkedin.com/company/agiles-telecoms/about/" target="_blank">
                                        <font-awesome-icon icon="fa-brands fa-linkedin-in" size="1x" :style="{ color: '#ffffff' }" />
                                    </a>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
                                <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 class="text-light mb-0">Liens importants</h3>
                                </div>
                                <div class="link-animated d-flex flex-column justify-content-start" style="text-transform: capitalize !important;">
                                    <router-link to="/">
                                        <a class="text-light mb-2"><i class="bi bi-arrow-right text-primary me-2"></i>Accueil</a>
                                    </router-link>

                                    <router-link to="/agiles-store">
                                        <a class="text-light"><i class="bi bi-arrow-right text-primary me-2"></i>Notre Store</a>
                                    </router-link>

                                    <router-link to="/contactez-nous">
                                        <a class="text-light"><i class="bi bi-arrow-right text-primary me-2"></i>Contactez-nous</a>
                                    </router-link>

                                    <router-link to="/a-propos-de-nous">
                                        <a class="text-light mb-2"><i class="bi bi-arrow-right text-primary me-2"></i>A propos de nous</a>
                                    </router-link>

                                    <router-link to="/nos-services-et-produits">
                                        <a class="text-light mb-2"><i class="bi bi-arrow-right text-primary me-2"></i>Nos Services & produits</a>
                                    </router-link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid text-white Anime animated fadeInDown" style="background: #061429;">
            <div class="container text-center">
                <div class="row justify-content-end">
                    <div class="">
                        <div class="d-flex align-items-center justify-content-center" style="height: 75px;">
                            <p class="mb-0">&copy; <router-link class="text-white border-bottom" to="/">agilestelecoms</router-link>. Tous droits reservé. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer End -->


    <!-- Back to Top -->
    <router-link to="/" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top">
        <font-awesome-icon icon="arrow-up" size="1x" :style="{ color: '#ffffff' }" />
    </router-link>
</div>


</template>

<style scoped>

.Anime{
    animation-duration: 3s;
}
.fadeInU{
    animation-duration: 2s;
}
.Abt div{
    animation-duration: 3s;
}




:root {
  --bordersize: 1px;
}

.brands {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.brand {
  flex: 1 0 25%;
  border-left: var(--bordersize) dashed #ccc;
  border-bottom: var(--bordersize) dashed #ccc;
  margin-left: calc(-1 * var(--bordersize));
  margin-bottom: calc(-1 * var(--bordersize));
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand img {
  margin: 3px;

  transition: transform 0.2s 0.05s;
}
.brand:hover img {
  transform: scale(1.3);
  transition-delay: 0s;
}

</style>