<template>
    <div class="Detail__recrutement">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbar />

          <div class="container-fluid bg-primary py-5 bg-header" style="margin-bottom: 90px;">
            <div class="row py-5">
                <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                    <router-link to="/" class="h5 text-white m-2">Accueil</router-link>
                    <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                    <router-link to="/agiles-recrute" class="h5 text-white m-2">Recrutements</router-link>
                    <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                    <a href="" class="h5 text-white m-2">Description</a>
                </div>
            </div>
        </div>


        <!-- Recrutement Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- Recrutement Start -->
                <div class="mb-5" style="margin: 0px !important;">


                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>AGILES TELECOMS RECRUTE</center>
                    </h1><br>
                   
  
                        <div class=" justify-content-center ">

                            <div v-if="annonce" class="wow fadeInUp" data-wow-delay="0.1s">
                                <div>
                                    <span style="color: #052E4A; font-weight: 700;">POSTE : </span> 
                                    <span class="Titre__poste mb-4"> {{ annonce.title }} </span><br><br>
                                </div><br>


                                    <h3 class="Titre">- Description du profil</h3><br>

                                    <span class="Sous__titre">Diplômes requis</span><br><br>
                                        <div v-html="annonce.diplome">
                                          </div>  <br><br>
                                            
                                    <span class="Sous__titre">Durée d'expérience</span><br><br>
                                        <div v-html="annonce.dure_experience"></div> <br><br>

                                    <div class="desc__competences">
                                        <h3 class="Titre">- Compétences réquises</h3><br>

                                        <span class="Sous__titre">Compétences techniques</span><br><br>
                                           <div v-html="annonce.comp_tech"></div> <br><br>

                                        <span class="Sous__titre">Aptitudes professionnelles</span><br><br>
                                          <div v-html="annonce.aptitude_pro"></div> <br><br>
                                    </div>
                                    
                            </div>
                        </div>
                            

                   

            <center>
                <button onclick="myFunction()"
                style="border-radius: 100%;
                background-color: #F7A51B;
                color: #ffffff;
                padding: 30px;
                border: none;"
                >Postuler</button>
            </center>    

            <!-- postuler Form Start -->
            <div class="rounded p-5" id="commentt">
              <div class="row mt-5">
                <div class="col-lg-2"></div>

                <div class="col-lg-8">

                    <h4 class="mb-5" style="color: #F7A51B;">REMPLISSEZ NOTRE FORMULAIRE DE CANDIDATURE</h4>

                    
                    <div class="login-box">
                        <form ref="form" @submit.prevent="submitpostulerForm">
                        <!-- Reste du formulaire -->
                        <div class="user-box">
                            <input type="text" v-model="nom" required="">
                            <label>Quel est votre nom ?</label>
                        </div>

                        <div class="user-box">
                            <input type="text" v-model="prenom" required="">
                            <label>Quel est votre prénom ?</label>
                        </div>

                        <div class="user-box">
                            <input type="email" v-model="email" required>
                            <label>Quel est votre email ?</label>
                        </div>

                        <div class="user-box">
                            <input type="text" v-model="phone" required="">
                            <label>Quel est votre numéro de téléphone mobile ?</label>
                        </div>

                        <label style="color: #052E4A;">Ajoutez votre CV</label>
                        <div class="user-box">
                            <input
                            type="file"
                            ref="file"
                            id="file"
                            @change="handleFileChange"
                            accept=".doc, .docx, .pdf, .txt"
                            required
                            >
                        </div>

                        <label style="color: #052E4A;">Motivation</label>
                        <div class="user-box">
                            <textarea
                            class="form-control"
                            type="text"
                            placeholder="Ecrire ici ..." id="motivation"
                            style="height: 100px"
                            v-model="motivation"
                            required=""></textarea>
                        </div><br><br>
                        <button class="btn btn-outline-warning w-100" style="background-color: #F7A51B; color: white;">Soumettre</button>
                        </form>
                    </div>



                </div>

                <div class="col-lg-3"></div>
              </div>
            </div>
            <!-- postuler Form End -->
                    
                </div>
                <!-- Recrutement End -->
            </div> 
        </div>
        <!-- Recrutement End -->

      </div>
      <!-- Navbar & Carousel End -->
      
  
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbar from '@/components/NavNavbar.vue'
  import FooterView from '@/components/FooterView.vue'
  import Swal from "sweetalert2";

  
  export default {
    name: 'Detail__recrutement',
    components: {
      NavNavbar,
      FooterView,
    },
    data() {
        return {
            annonce: null,
            annonces: [],
            isLoading: false,
            selectedFile: null,
            postuler: null,
            nom: "",
            prenom: "",
            email: "",
            phone: "",
            motivation: "",
            image: "",
            apipostuler: "",
        };
    },

        
    mounted(){
        this.getDetAnnonces(this.$route.params.id);
    },

    methods: {
        async getDetAnnonces(id) {
            this.isLoading = true;
            await this.$store.dispatch("staticdata/getDetailAnnonces", id)
            .then(
                (response) => {
                    this.isLoading = false;
                    this.annonce = response.data;
                },
                (error) => {
                    this.isLoading = false;
                    console.log("error--- ", error);
                }
            );
        },

        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },

        async submitpostulerForm() {
      this.loading = true;
      if (this.selectedFile) {
        let formData = new FormData();
        formData.append("cv", this.selectedFile);
        formData.append("nom", this.nom);
        formData.append("prenom", this.prenom);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("motivation", this.motivation);
        formData.append("annonce_id", this.$route.params.id);  // Ajoutez l'ID de l'annonce ici

        await this.$store.dispatch("user/postulerRequest", { annonceId: this.$route.params.id, formData }).then(
          (response) => {
            this.loading = false;
            this.$refs.form.reset();
            this.prenom = this.nom = this.email = this.motivation = this.phone = "";
            const message = `${response.postulant.nom} ${response.postulant.prenom}, votre candidature a été soumise avec succès!`;
            Swal.fire({
              title: "Succès",
              text: message,
              icon: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.apipostuler =
              (error.response &&
                error.response.data &&
                error.response.data.postuler) ||
              error.postuler ||
              error.toString();
            Swal.fire({
              title: "oups :)",
              text:
                (error.response &&
                  error.response.data &&
                  error.response.data.message +
                  " " +
                  JSON.stringify(error.response.data.errors)) ||
                error.data ||
                error.postuler ||
                error.toString(),
              icon: "error",
            });
          }
        );
      } else {
        this.loading = false;
        Swal.fire({
          title: "Oups :)",
          text: "Veuillez ajouter un fichier CV.",
          icon: "error",
        });
      }
    },
    }
}
  </script>
  
  <style scoped>

        .Titre__poste{
            font-size: 20px; font-weight: 400; color: #61C7F2;
        }
        .Titre{
            color: #F7A51B; font-weight: 500;
        }
        .Sous__titre{
            color: #052E4A; font-weight: 500; font-size: 22px;
        }
        .Titre__items{
            font-size: 21px;
        }
        .Titre_li_items{
            font-size: 21px;
        }
        #commentt{
            display: none;
        }

    .login-box h2 {
        margin: 0 0 30px;
        padding: 0;
        color: #000;
        text-align: center;
    }

    .login-box .user-box {
        position: relative;
    }

    .login-box .user-box input {
        width: 100%;
        padding: 10px 0;
        font-size: 16px;
        color: #052E4A;
        margin-bottom: 30px;
        border: none;
        border-bottom: 1px solid #000;
        outline: none;
        background: transparent;
    }
    .login-box .user-box label {
        position: absolute;
        top:0;
        left: 0;
        padding: 10px 0;
        font-size: 16px;
        color: #052E4A;
        pointer-events: none;
        transition: .5s;
    }

    .login-box .user-box input:focus ~ label,
    .login-box .user-box input:valid ~ label {
        top: -20px;
        left: 0;
        color: #61C7F2;
        font-size: 12px;
    }

    .login-box form a {
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        color: #61C7F2;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        overflow: hidden;
        transition: .5s;
        margin-top: 40px;
        letter-spacing: 4px
    }

    .login-box a:hover {
        background: #61C7F2;
        color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 5px #61C7F2,
                0 0 25px #61C7F2,
                0 0 50px #61C7F2,
                0 0 100px #61C7F2;
    }

    .login-box a span {
        position: absolute;
        display: block;
    }

    .login-box a span:nth-child(1) {
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, transparent, #61C7F2);
        animation: btn-anim1 1s linear infinite;
    }

    @keyframes btn-anim1 {
        0% {
            left: -100%;
        }
        50%,100% {
            left: 100%;
        }
    }

    .login-box a span:nth-child(2) {
        top: -100%;
        right: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(180deg, transparent, #61C7F2);
        animation: btn-anim2 1s linear infinite;
        animation-delay: .25s
    }

    @keyframes btn-anim2 {
        0% {
            top: -100%;
        }
        50%,100% {
            top: 100%;
        }
    }

    .login-box a span:nth-child(3) {
        bottom: 0;
        right: -100%;
        width: 100%;
        height: 2px;
        background: linear-gradient(270deg, transparent, #61C7F2);
        animation: btn-anim3 1s linear infinite;
        animation-delay: .5s
    }

    @keyframes btn-anim3 {
        0% {
            right: -100%;
        }
        50%,100% {
            right: 100%;
        }
    }

    .login-box a span:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(360deg, transparent, #61C7F2);
        animation: btn-anim4 1s linear infinite;
        animation-delay: .75s
    }

    @keyframes btn-anim4 {
        0% {
            bottom: -100%;
            }
        50%,100% {
            bottom: 100%;
        }
    }

  </style>