<template>
    <div class="container-fluid">
      <div class="row">
  
        <SidebarDash />
  
        <!-- Contenu Principal -->
        <div class="col">
            <main class="content">

                <h6 class="mb-5 mt-5" style="color: #F7A51B;">----------DÉTAILS DES RESSOURCES</h6>
                

                <div class=" justify-content-center ">

                    <div v-if="agileressource" class="wow fadeInUp" data-wow-delay="0.1s">
                    
                        <div class="card p-3">

                            <div class="col-md-6">

                                <h3 style="font-weight: 600;">{{ agileressource.title }}</h3>

                                <h3 class="mt-5" style="font-weight: 600;">{{ agileressource.name }}</h3>

                                <p style="font-size: 18px !important; font-weight: 500 !important;"> {{ agileressource.description }}</p>

                                <a :href="agileressource.fichier" target="_blank" style="color: #F7A51B; text-decoration: underline;">Fichier à télécharger</a>

                            </div>

                            <div class="col-md-6 mt-2">
                                <img v-bind:src="agileressource.illustration" class="img-fluid" alt="">
                            </div>

                        </div>
                    </div>

                    
                </div>

            </main>
        </div>

      </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'
  
  export default {
    name: "VoirRessources",
    components: {
        SidebarDash
    },

    data() {
        return {
            agileressource: null,
            agileressources: [],
            isLoading: false,
            selectedFile: null,
        };
    },

        
    mounted(){
        this.getDetRessources(this.$route.params.id);
    },

    methods: {
        async getDetRessources(id) {
            this.isLoading = true;
            await this.$store.dispatch("staticdata/fetchVoirRessources", id)
            .then(
                (response) => {
                    this.isLoading = false;
                    this.agileressource = response.data;
                },
                (error) => {
                    this.isLoading = false;
                    console.log("error--- ", error);
                }
            );
        },

      goToPage(newPage) {
        if (newPage >= 1 && newPage <= this.totalPages) {
          this.page = newPage;
        }
      }
    }
 };

</script>

<style scoped>
    .content {
        margin-left: 250px; /* Largeur du sidebar */
        padding: 20px;
    }

    @media (max-width: 768px) {
    .sidebar {
        transform: translateX(-250px);
    }

    .sidebar.show {
        transform: translateX(0);
    }

    .content {
        margin-left: 0;
    }
    }
</style>
