<template>
    <div class="CatalogueView" >
      <NavNavbarSI />

      <div class="cata m-3">
        <img src="../../assets/img/atcat01.png" alt="First slide">
        <img src="../../assets/img/atcat02.png" alt="Second slide">
        <img src="../../assets/img/atcat03.png" alt="Second slide">
        <img src="../../assets/img/atcat04.png" alt="Second slide">
      </div>
        
    </div>
</template>
  
  <script>
  // @ is an alias to /src
import NavNavbarSI from '@/components/NavNavbarSI.vue'

  
  export default {
    name: 'CatalogueView',
    components: {
      NavNavbarSI,
    }
  }
  </script>

<style scoped>

/* :root {
    --primary: #61C7F2 #F7A51B;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */


</style>