<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />


        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>SMS FIREWALL</center>
                    </h1>

                    <div>
                        <span class="Annonce mb-4"> 
                            La meilleure protection réseau contre le trafic SMS non autorisé <br>

                            Détection et atténuation fiables de toute fraude par SMS              
                        </span>
                    </div>
                    

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items">
                            <span style="color: #61C7F2;"></span> 
                            PROTEI SMS Firewall est destiné à protéger le réseau de l'Opérateur des principales
                             menaces liées au spam et à la fraude par SMS. Les principaux objectifs de la solution 
                            de sécurité SMS sont de détecter et d'atténuer les fraudes SMS causées par l'usurpation 
                            de SMS, la simulation de SMS, l'inondation de SMS et d'autres types de fraude technique. <br><br>


                            Des critères de filtrage peuvent être définis pour différents types de trafic. Des règles peuvent être
                            définies pour MO ou MT SMS, pour une plage MSISDN particulière, un opérateur, une plage de titre global ou 
                            un expéditeur de message SMPP. Ces critères incluent la liste des paramètres SS7 à vérifier lors du traitement 
                            des messages entrants MO ou MT en provenance de l'étranger, ainsi que la liste des chiffres définissant les limites 
                            de bande passante et de routage pour le trafic SMS, comme les limites d'intensité du trafic SMS à partir d'un MSISDN, 
                            SMSC ou autre réseau particulier les éléments, plage de numéros de destination à laquelle les messages de la source 
                            mentionnée ci-dessus peuvent être adressés, plage de numéros d'origine à partir de laquelle les messages peuvent être envoyés, etc. <br><br>


                            Les capacités de filtrage et de détection anti-spoofing et anti-fake sont prises en charge selon IR71. PROTEI SMS Firewall
                            prend en charge le filtrage par mots-clés, la détection de spam intellectuel basée sur l'analyse heuristique du corps du 
                            message et des critères statistiques tels que le nombre de messages identiques / similaires d'une source, d'un réseau, 
                            à un destinataire, etc. <br><br>


                            <!-- <ul style="font-size: 21px !important; color: #052E4A;">
                                <li>Système d'information unifié pour l'unité d'intervention d'urgence permettant <br> la réception,
                                    le suivi et la gestion des tickets d'urgence à partir de divers systèmes <br>
                                    (112/911, surveillance écologique, système d'analyse CCTV / vidéo etc.)</li>
                                <li>Bus d'échange d'informations</li>
                                <li>Sous-système d'aide à la décision</li>
                                <li>Système d'analyse d'urgence / de prévision d'urgence</li>
                            </ul> -->
                        </p>
                        <!-- <p class="Titre__items">
                            La solution PROTEI Smart / Safe city est conçue pour créer un écosystème de ville intelligente avancé, 
                            identifier les vulnérabilités et menaces potentielles, identifier et prédire les menaces actuelles et potentielles 
                            liées à la sécurité publique des menaces de la municipalité / ville / province, optimiser la coordination entre tous 
                            les participants pendant gestion / élimination des urgences, permettant les communications entre les participants de 
                            toutes les initiatives de sécurité publique existantes via le bus unifié.
                        </p> -->
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li>Outil efficace pour la gestion et la protection du trafic SMS au sein du réseau mobile</li>
                            <li>Critères de filtrage extrêmement flexibles et capacités anti-spam améliorées</li>
                            <li>Prise en charge personnalisée des listes blanches, noires et des filtres</li>
                            <li>Conformité aux normes internationales</li>
                            <li>Intégration complète avec les systèmes existants</li>
                            <li>Mise en œuvre facile</li>
                        </ul>
                        

                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
        NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>