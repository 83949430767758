<template>
    <div class="Detail__sTORE">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbar />
          <div class="container-fluid bg-primary py-5 bg-header-solution" style="margin-bottom: 90px;">
              <div class="row py-5">
                  <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                      <router-link to="/agiles-store" class="h5 text-white m-2">Agiles Store</router-link>
                      <font-awesome-icon icon="fa-regular fa-circle" :style="{ color: '#ffffff' }" />
                      <router-link to="/agiles-solutions" class="h5 text-white m-2">Solutions</router-link>
                  </div>
              </div>
          </div>

  
          <h1 class="mb-5" style="font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
            <center>AGILES SOLUTIONS</center> 
          </h1>
          <center><h3 class="mb-5" style="color: #052E4A;">En savoir plus sur Agiles Solutions</h3> </center><br>


          <div class="container">
            
            <div class="row mt-5">

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/local.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Local Number for Visitors</h2>
                        <router-link to="/agiles-solutions/local-number-for-visitor" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/sbc.jpeg') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Section Boarder Controller (SBC)</h2>
                        <router-link to="/agiles-solutions/sessions-border" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/personal_ring.jpeg') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Personal Ring Back Tone & Video Ring Back Tone</h2>
                        <router-link to="/agiles-solutions/personal-ring-back-tone" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/safe.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Smart/Safe City</h2>
                        <router-link to="/agiles-solutions/smart-city" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/sms.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">SMS Firewall</h2>
                        <router-link to="/agiles-solutions/sms-firewall" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/gateway.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">USSD Gateway</h2>
                        <router-link to="/agiles-solutions/server-ussd" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/wifi.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">Wifi Offload</h2>
                        <router-link to="/agiles-solutions/wifi-offload" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

              <div class="col-md-4 mb-5">
                  <div class="card" 
                  :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/gatew.png') +
                        ')',
                    }"
                  >
                    <div class="card-content">
                        <h2 class="mb-5 card-title text-white">SS7 Firewall</h2>
                        <router-link to="/agiles-solutions/ss7-firewall" class="button text-white text-center" style="width: 200px;">Parcourir</router-link>
                    </div>
                  </div> 
              </div>

               
            </div>    
          </div>    




      </div>
      <!-- content End -->
      
  
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbar from '@/components/NavNavbar.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Detail__sTORE',
    components: {
      NavNavbar,
      FooterView
    }
  }
  </script>


  <style scoped>

    /* --bleu: #61C7F2;
    --orange: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E; */


    /* .card {
      color: #61C7F2;
      background-size: cover;
      padding: 11rem 0 0;
      max-width: 35ch;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .card-content {
      --padding: 1rem;
      padding: var(--padding);
      background: linear-gradient(
        hsl(0 0% 0% / 0),
        hsl(20 0% 0% / 0.3) 20%,
        rgba(0, 0, 0, 0.317)
      );
      transform: translateY(100%);
      transition: transform 500ms ease;
    }


     */



.card {
  color: #F7A51B;
  background-size: cover;
  padding: 9rem 0 0;
  max-width: 35ch;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.5rem;
  overflow: hidden;
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(
    #00000000,
    #00000074 20%
  );
  transform: translateY(70%);
  transition: transform 500ms ease;
}

.card:hover .card-content {
  transform: translateY(0);
}

.card-title {
  font-size: 23px;
  position: relative;
  width: 250px;
}

.card-title::after {
  content: "";
  position: absolute;
  height: 4px;
  left: calc(var(--padding) * -1);
  bottom: 0;
  width: calc(100% + var(--padding));
  background: #F7A51B;
  transform: scaleX(0);
  transition: transform 500ms ease;
}

.card:hover .card-title::after {
  transform: scaleX(1);
}


.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: #13171ba5;
  background-color: transparent;
  padding: 0.5em 1.25em;
  border: 2px solid #F7A51B;
  border-radius: 0.25em;
}

.button:focus,
.button:hover {
  border: 2px solid #ffffff;
  background-color: #F7A51B !important;
}

  </style>
  