<template>
  <router-view/>
</template>

<!-- <script>

        $(".box") .click (function () {
          $ (".box") .removeClass ("active");
          $ (this) .addClass ("active");
        });
  </script> -->

<style>
/* :root {
    --primary: #61C7F2;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */

*{
    font-family: "Raleway", "Odoo Unicode Support Noto", sans-serif !important; 
}
.btn-dark {
    /* color: #fff; */
    background-color: transparent !important;
    border: none !important;
}

</style>
