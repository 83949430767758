<template>
  <div class="container-fluid">
    <div class="row">

      <SidebarDash />

      <!-- Contenu Principal -->
      <div class="col">
        <main class="content">

          <h6 class="mb-3 mt-2" style="color: #F7A51B;">----------AJOUTEZ UNE RESSOURCE</h6>

          <div class="cadre">
    <div class="card p-3" style="width: 45rem;">
      <form ref="form" @submit.prevent="submitaddRessources">

        <div class="mb-3">
        <div class="mb-3">
          <label for="title" class="form-label">Ajouter un titre</label>
          <input 
            type="text" 
            class="form-control" 
            id="title"
            v-model="title"
            required
          >
        </div><br>
          <label for="illustration" class="form-label">Ajouter une illustration/image</label>
          <input 
            type="file" 
            class="form-control" 
            id="illustration"
            ref="illustration"
            @change="handleFileChange('illustration')"
            accept=".png, .jpg, .jpeg"
            required
          >
        </div><br>

        <div class="mb-3">
          <label for="fichier" class="form-label">Ajouter un fichier</label>
          <input 
            type="file" 
            class="form-control" 
            id="fichier"
            ref="fichier"
            @change="handleFileChange('fichier')"
            accept=".doc, .docx, .pdf, .txt"
            required
          >
        </div>

        <button type="submit" class="btn btn-outline-warning w-100" style="background-color: #F7A51B; color: white;">Enregistrer</button>
      </form>
    </div>
  </div>
        </main> <!-- main end-->
      </div>
      <!-- Contenu Principal -->

    </div>
</div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'

import Swal from "sweetalert2";

export default {
  name: "AddRessources",
  components: {
    SidebarDash
  },
  data() {
    return {
      title: "",
      illustration: null,
      fichier: null,
    };
  },
  methods: {
    handleFileChange(type) {
      if (type === "illustration") {
        this.illustration = this.$refs.illustration.files[0];
      } else if (type === "fichier") {
        this.fichier = this.$refs.fichier.files[0];
      }
    },
    async submitaddRessources() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("illustration", this.illustration);
      formData.append("fichier", this.fichier);

      try {
        await this.$store.dispatch("user/addRessRequest", formData);
        Swal.fire("Succès", "Les ressources ont été ajoutées avec succès.", "success");
      } catch (error) {
        Swal.fire("Erreur", "Il y a eu un problème lors de l'ajout des ressources.", "error");
      }
    },
  },
};
</script>

<style scoped>
  .content {
      margin-left: 250px; /* Largeur du sidebar */
      padding: 20px;
  }

  @media (max-width: 768px) {
  .sidebar {
      transform: translateX(-250px);
  }

  .sidebar.show {
      transform: translateX(0);
  }

  .content {
      margin-left: 0;
  }
  }

  .cadre {
    position: relative;

    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cadre.card {
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
</style>