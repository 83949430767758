<template>
    <div class="Recrutement">
      <!-- Navbar Start -->
      <div class="container-fluid position-relative p-0">
        <NavNavbar />
  
        <div
          class="container-fluid bg-primary py-5 bg-header-recrutement"
          style="margin-bottom: 90px"
        >
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <router-link to="/" class="h5 text-white m-2">Accueil</router-link>
              <font-awesome-icon
                icon="fa-regular fa-circle"
                :style="{ color: '#ffffff' }"
              />
              <a href="" class="h5 text-white m-2">Recrutements</a>
            </div>
          </div>
        </div>
      </div>
      <!-- Navbar End -->
  
      <h1
        class="mb-5"
        style="
          font-size: 50px;
          background: linear-gradient(#61c7f2, #f7a51b);
          -webkit-background-clip: text;
          color: transparent;
        "
      >
        <center>AGILES TELECOMS RECRUTE</center>
      </h1>
  
      <!-- Search Form Start -->
      <div
        class="d-flex align-items-center justify-content-center wow slideInUp"
        data-wow-delay="0.1s"
      >
        <div class="input-group" style="max-width: 600px">
          <input
            v-model="search" required
            type="text"
            class="form-control bg-transparent border-primary p-3"
            placeholder="Quel poste recherchez-vous ?"
          />
          <button class="btn btn-primary px-4" v-on:click="getSearchDataForm(this.search)">
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
      <!-- Search Form End -->
  
      <!-- recrutement card Start -->
      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">

          <!-- Annonces list Start -->
          <section v-if="loadingGetAnnonce">
            <div class="row g-5">
              <div class="col-12 text-center">
                <h4>Chargement en cours ...</h4>
              </div>
            </div>
          </section>
  
          <section v-else>
            <section v-if="annonces.length == 0">
              <div class="row g-5">
                <div class="col-12 text-center text-warning">
                  <h4>Aucune offre disponible</h4>
                </div>
              </div>
            </section>
            <section v-else>
              <div class="row g-5">
                <div
                  class="col-md-4 wow slideInUp"
                  v-for="index in displayedItems"
                  :key="index.id"
                  data-wow-delay="0.1s"
                >
                  <router-link
                    :to="{ name: 'detailrecrutement', params: { id: index.id } }"
                  >
                    <div class="blog-item bg-light rounded overflow-hidden">
                      <div class="blog-img position-relative overflow-hidden">
                        <img
                          class="img-fluid"
                          src="../../assets/img/recrutementt.jpeg"
                          alt=""
                        />
                      </div>
                      <div class="p-4">
                        <h5 class="mb-3" style="font-weight: 500 !important">
                          - {{ index.title }}
                        </h5>
                        <p class="" style="color: #f7a51b">
                          {{ index.entreprise }}
                        </p>
                        <br /><br />
                        <div class="">
                          <small class="me-3 start-0"> place(s)</small>
                          <small style="float: right !important"
                            >Publié le {{ index.date }}</small
                          >
                        </div>
                      </div>
                      
                    </div>
                  </router-link>
                </div>
              </div>
  
              <!-- recrutement pagination start -->
              <div class="col-12 wow slideInUp mt-5" data-wow-delay="0.1s">
                <nav aria-label="Page navigation">
                  <ul class="pagination pagination m-0">
                    <li
                      @click="goToPage(page - 1)"
                      :class="page == 1 ? 'page-item disabled' : 'page-item'"
                    >
                      <a
                        class="page-link rounded-0"
                        href="#"
                        aria-label="Previous"
                      >
                        <span aria-hidden="true"
                          ><i class="bi bi-arrow-left"></i
                        ></span>
                      </a>
                    </li>
  
                    <li
                      v-for="p in totalPages"
                      :key="p"
                      @click="goToPage(p)"
                      :class="p == page ? 'page-item active' : 'page-item'"
                    >
                      <a class="page-link" href="#">{{ p }}</a>
                    </li>
  
                    <li
                      @click="goToPage(page + 1)"
                      :class="
                        page == totalPages ? 'disabled page-item' : 'page-item'
                      "
                    >
                      <a class="page-link rounded-0" href="#" aria-label="Next">
                        <span aria-hidden="true"
                          ><i class="bi bi-arrow-right"></i
                        ></span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- recrutement pagination end -->
            </section>
          </section>
  
          <div class="row mt-5">
            <div class="col-lg-6">
              <div class="banner">
                <img src="../../assets/img/etpe.jpeg" class="img-fluid" alt="" />
              </div>
            </div>
            <div class="col-lg-6 mt-4" style="">
              <h2>Trouver votre prochain emploi chez nous!</h2>
              <p style="font-size: 22px">
                Le recrutement est une étape fondamentale et incontournable pour
                chaque organisme professionnel, il s’agit d’un ensemble d’actions
                mises en œuvre pour trouver un candidat correspondant aux besoins
                d'une organisation pour un poste donné.
              </p>
            </div>
          </div>
          <br /><br /><br />
  
          <div class="row mt-5">
            <div class="col-lg-3">
              <h3>
                Avec <br />
                <span style="color: #61c7f2">AGILES TELECOMS</span>, <br />
                les bonnes portes s'ouvrent à vous.
              </h3>
            </div>
            <div class="col-lg-1"></div>
  
            <div class="col-lg-5">
              <h4 class="mb-5" style="color: #f7a51b">
                REMPLISSEZ NOTRE FORMULAIRE DE CANDIDATURE SPONTANNÉE
              </h4>
  
              <div class="login-box">
                <form ref="form" @submit.prevent="submitcandidatSpontForm">
                  <div class="user-box">
                    <input type="text" id="nom" v-model="nom" required />
                    <label>Quel est votre nom ?</label>
                  </div>
  
                  <div class="user-box">
                    <input type="text" id="prenom" v-model="prenom" required />
                    <label>Quel est votre prénom ?</label>
                  </div>
  
                  <div class="user-box">
                    <input type="email" id="email" v-model="email" required />
                    <label>Quel est votre email ?</label>
                  </div>
  
                  <div class="user-box">
                    <input type="text" id="phone_nb" v-model="phone" required />
                    <label>Quel est votre numéro de télépone mobile ?</label>
                  </div>
  
                  <label style="color: #052e4a">Ajoutez votre CV</label>
                  <div class="user-box">
                    <input
                      type="file"
                      ref="file"
                      id="file"
                      @change="handleFileChange"
                      accept=".doc, .docx, .pdf, .txt"
                      required
                    />
                  </div>
  
                  <button
                    v-if="loading"
                    class="btn btn-outline-warning w-100 disabled"
                    type="submit"
                    style="background-color: #c3c3c3; color: white"
                  >
                    Opération en cours ...
                  </button>
                  <button
                    v-else
                    class="btn btn-outline-warning w-100"
                    type="submit"
                    style="background-color: #f7a51b; color: white"
                  >
                    Soumettre
                  </button>
                </form>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
        </div>
      </div>
      <!-- recrutement card End -->
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbar from "@/components/NavNavbar.vue";
  import FooterView from "@/components/FooterView.vue";
  import Swal from "sweetalert2";
  
  export default {
    name: "RecrutementView",
    components: {
      NavNavbar,
      FooterView,
    },
    data() {
      return {
        annonces: [],
        loading: false,
        loadingGetAnnonce: false,
        loadingGetAnnoncebySearch: false,
        selectedFile: null,
        searchResult: null,
        searchIsLoading: false,
        nom: "",
        prenom: "",
        email: "",
        phone: "",
        image: "",
        apicdtSpont: "",
        search:"",
        page: 1,
        itemsPerPage: 6,
      };
    },
  
    mounted() {
      this.annoncesList();
    },
    computed: {
      displayedItems() {
        const startIndex = (this.page - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.annonces.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.annonces.length / this.itemsPerPage);
      },
    },
    methods: {
      async annoncesList() {
        this.loadingGetAnnonce = true;
        await this.$store.dispatch("staticdata/fetchAnnonces").then(
          (response) => {
            this.annonces = response.data;
            this.loadingGetAnnonce = false;
          },
          (error) => {
            console.log("error--- ", error);
            this.loadingGetAnnonce = false;
          }
        );
      },

      async getSearchDataForm(annonceTitle){
        this.annonces = ""
        this.loadingGetAnnonce = true;
        await this.$store.dispatch("staticdata/fetchSpecificAnnonces", annonceTitle)
          .then(
              (response) => {
                console.log("response ---", response)
                this.loadingGetAnnonce = false;
                this.annonces = response.data;
              },
              (error) => {
                this.loadingGetAnnonce = false;
                console.log("error--- ", error);
              }
          );
      },

      goToPage(newPage) {
        if (newPage >= 1 && newPage <= this.totalPages) {
          this.page = newPage;
        }
      },
      handleFileChange(event) {
        this.selectedFile = event.target.files[0];
      },

      async submitcandidatSpontForm() {
        this.loading = true;
  
        if (this.selectedFile) {
          let formData = new FormData();
          formData.append("fichiers", this.selectedFile);
          formData.append("nom", this.nom);
          formData.append("prenom", this.prenom);
          formData.append("email", this.email);
          formData.append("phone", this.phone);
  
          await this.$store.dispatch("user/candidatSpontRequest", formData).then(
            (response) => {
              this.loading = false;
              this.$refs.form.reset();
              this.prenom = this.nom = this.email = this.phone = "";
              const message =
                response.postulant.nom +
                " " +
                response.postulant.prenom +
                ", votre candidature a été enregistrer avec succès!";
              Swal.fire({
                title: "Félicitation",
                text: message,
                icon: "success",
              });
            },
            (error) => {
              this.loading = false;
              this.apicdtSpont =
                (error.response &&
                  error.response.data &&
                  error.response.data.recrutement) ||
                error.recrutement ||
                error.toString();
              this.loading = false;
  
              Swal.fire({
                title: "Oups :)",
                text:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message +
                      " " +
                      JSON.stringify(error.response.data.errors)) ||
                  error.data ||
                  error.recrutement ||
                  error.toString(),
                icon: "error",
              });
            }
          );
        } else {
          Swal.fire({
            title: "Oups :)",
            text: "Echec de validation du formulaire, vérifiez que vous avez chargé un fichier.",
            icon: "error",
          });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #000;
    text-align: center;
  }
  
  .login-box .user-box {
    position: relative;
  }
  
  .login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #052e4a;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    background: transparent;
  }
  .login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #052e4a;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #61c7f2;
    font-size: 12px;
  }
  
  .login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #61c7f2;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 40px;
    letter-spacing: 4px;
  }
  
  .login-box a:hover {
    background: #61c7f2;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #61c7f2, 0 0 25px #61c7f2, 0 0 50px #61c7f2,
      0 0 100px #61c7f2;
  }
  
  .login-box a span {
    position: absolute;
    display: block;
  }
  
  .login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #61c7f2);
    animation: btn-anim1 1s linear infinite;
  }
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  
  .login-box a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #61c7f2);
    animation: btn-anim2 1s linear infinite;
    animation-delay: 0.25s;
  }
  
  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  
  .login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #61c7f2);
    animation: btn-anim3 1s linear infinite;
    animation-delay: 0.5s;
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  
  .login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #61c7f2);
    animation: btn-anim4 1s linear infinite;
    animation-delay: 0.75s;
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  </style>
  