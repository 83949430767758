<template>
  <div class="DetailServices">

    <!-- Navbar & Carousel Start -->
    <div class="container-fluid position-relative p-0">

        <NavNavbarSI />

        
          <div class="container">
            <h1 class="mb-5" style="font-size: 35px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
              SYSTEME D'INFORMATION
            </h1>
          </div>

          <div class="container p-5">
            <div class="mb-5 description" style="margin: 0px !important;">

              <div class="content" style="color: #052A4A !important;">

                <div class="row">
                <hr style="height: 1px;">
                  <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                    <h4 style="color: #052A4A; font-size: 25px;">Cloud Computing</h4>
                  </div>
                  <div class="col-md-7" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                    <ul>
                      <li>Conteneur: Docker</li>
                      <li>Orchestration: Kubernets</li>
                      <li>Automatisation: Ansible</li>
                    </ul>
                  </div>
                </div>
                <hr style="height: 1px;">


                <div class="row">
                  <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                    <h4 style="color: #052A4A; font-size: 25px;">Big Data & IoT(Internet des Objets)</h4>
                  </div>
                  <div class="col-md-7" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                    <ul>
                      <li>Collecte des données</li>
                      <li>Traitement des données</li>
                      <li>Analyse et stockage</li>
                      <li>Visualisation</li>
                    </ul>
                  </div>
                </div>
                <hr style="height: 1px;">


                <div class="row">
                  <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                    <h4 style="color: #052A4A; font-size: 25px;">Virtualisation</h4>
                  </div>
                  <div class="col-md-7" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                    <ul>
                      <li>VMWare</li>
                      <li>KMV</li>
                    </ul>
                  </div>
                </div>
                <hr style="height: 1px;">


                <div class="row">
                  <div class="col-md-5" style="color: #61C7F2; padding-left: 30px;">
                    <h4 style="color: #052A4A; font-size: 25px;">Bases de données</h4>
                  </div>
                  <div class="col-md-7" style="font-size: 22px; border-left: 2px solid #F7A51B; padding-left: 30px;">
                    <ul>
                      <li>MySQL</li>
                      <li>Oracle</li>
                      <li>MongoDB</li>
                    </ul>
                  </div>
                </div>
                <hr style="height: 1px;">

                <div style="text-align: center !important; font-weight: 700;">
                  <span>CRM & ERP</span><br>
                  <span>Réseaux IP</span><br>
                  <span>WIFI</span>
                </div>
                

              </div>

            </div>
          </div>




    </div>
    <!-- content End -->
    


    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import NavNavbarSI from '@/components/NavNavbarSI.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'DetailServices',
  components: {
    NavNavbarSI,
    FooterView
  }
}
</script>

<style scoped>
/* :root {
    --primary: #61C7F2;
    --secondary: #F7A51B;
    --light: #EEF9FF;
    --dark: #091E3E;
} */
</style>
