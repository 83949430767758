import axios from 'axios'
import { baseURL } from "../../config/apiBase";


class StaticDataService {

    getAnnonces() {
      return axios.get(
              baseURL + 'annonces'
          )
          .then(response => {
              return response.data;
          });
    }

    getDetailAnnonces(annonceId) {
      return axios.get(
            baseURL + 'annonces/' + annonceId
        )
        .then(response => {
            return response.data;
        });
    }

    getSpecificAnnonces(annonceTitle) {
      return axios.get(
            baseURL + 'annonces?search=' + annonceTitle
        )
        .then(response => {
            return response.data;
        });
    }

    getRessources() {
      return axios.get(
            baseURL + 'agileressource'
        )
        .then(response => {
            return response.data;
        });
    }

    getVoirRessources(resourceId) {
      return axios.get(
        baseURL + 'agileressource/' + resourceId
      )
    }

    getFormatPostuler() {
      return axios.get(baseURL + 'formateur')
        .then(response => response.data)
        .catch(error => {
          console.error('Error fetching formateur data:', error);
          throw error;
        });
    }
    
    getCandidatPostuler() {
        return axios.get(
          baseURL + 'postuler'
        )
        .then(response => {
          return response.data;
        });
    }

    getCandidatSpontannee() {
      return axios.get(
        baseURL + 'candidature-spontannee'
      )
      .then(response => {
        return response.data;
      });
    }

    supprimerAnnonce = async (annonceId) => {
        const url = `https://backend.agilestelecoms.com/api/annonces/${annonceId}`;
        
        try {
          const response = await fetch(url, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            }
          });
      
          if (!response.ok) {
            throw new Error('Erreur lors de la suppression de l\'annonce');
          }
      
          return response.json(); 
        } catch (error) {
          throw new Error(error.message); 
        }
    };
  
    
  
}




export default new StaticDataService();