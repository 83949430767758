<template>
  <div class="container-fluid">
    <div class="row">

      <SidebarDash />

      <!-- Contenu Principal -->
      <div class="col">
        <main class="content">
          <button class="btn btn-dark d-md-none" @click="toggleSidebar">
            <i class="bi bi-list"></i>
          </button>
          <h6 class="mt-5" style="color: #61C7F2;">----------LISTE DES CANDIDATURES</h6>


            <!-- Liste candidats postulés card Start -->
            <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
              <div class="container py-5">

                <!-- candidats postulés list Start -->
                <section v-if="loadingGetPostuler">
                  <div class="row g-5">
                    <div class="col-12 text-center">
                      <h4>Chargement en cours ...</h4>
                    </div>
                  </div>
                </section>
        
                <section v-else>
                  <section v-if="postuler.length == 0">
                    <div class="row g-5">
                      <div class="col-12 text-center text-warning">
                        <h4>Aucune candidature enregistrée</h4>
                      </div>
                    </div>
                  </section>

                  <section v-else>
                    <div class="row">
                      <div
                        class="col-md-6 wow slideInUp mb-4" data-wow-delay="0.1s"
                        v-for="index in displayedItems"
                        :key="index.id">

                        <div class="blog-item bg-light card rounded overflow-hidden" style="border: 1px solid #61C7F2; border-radius: 10% 0% 10% 0% !important;">
                          <div class="blog-img card-header position-relative p-4" style="background: #FFFFFF !important">
                              <center>
                                <font-awesome-icon icon="fa-solid fa-user"  size="10x"/>
                              </center>
                          </div>
                          <div class="p-4">
                              <div class="d-flex mb-3">
                                  <small class="me-2" style="font-size: 20px !important">
                                      {{ index.nom }} {{ index.prenom }}</small>
                              </div>

                              <div class="d-flex mb-3">
                                  <small class="me-2"><font-awesome-icon icon="fa-solid fa-phone" /> {{ index.phone }}</small>
                                  <small><font-awesome-icon icon="fa-regular fa-envelope" />{{ index.email }}</small>
                              </div>

                              <div class="d-flex mb-3">
                                  <small class="me-3">{{ index.motivation }}</small>
                              </div>

                              <div class="d-flex mb-3">
                                 <a :href="index.cv" target="_blank" style="color: #F7A51B; text-decoration: underline;">Mon curriculum vitae</a>
                              </div>

                          </div>
                        </div>

                      </div>
                    </div>
        
                    <!-- Liste candidats postulés pagination start -->
                    <div class="col-12 wow slideInUp mt-5" data-wow-delay="0.1s">
                      <nav aria-label="Page navigation">
                        <ul class="pagination pagination m-0">
                          <li
                            @click="goToPage(page - 1)"
                            :class="page == 1 ? 'page-item disabled' : 'page-item'"
                          >
                            <a
                              class="page-link rounded-0"
                              href="#"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true"
                                ><i class="bi bi-arrow-left"></i
                              ></span>
                            </a>
                          </li>
        
                          <li
                            v-for="p in totalPages"
                            :key="p"
                            @click="goToPage(p)"
                            :class="p == page ? 'page-item active' : 'page-item'"
                          >
                            <a class="page-link" href="#">{{ p }}</a>
                          </li>
        
                          <li
                            @click="goToPage(page + 1)"
                            :class="
                              page == totalPages ? 'disabled page-item' : 'page-item'
                            "
                          >
                            <a class="page-link rounded-0" href="#" aria-label="Next">
                              <span aria-hidden="true"
                                ><i class="bi bi-arrow-right"></i
                              ></span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <!-- Liste candidats postulés pagination end -->

                  </section>
                </section>
                
              </div>
            </div>
            <!-- Liste candidats postulés card End -->


        </main>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'

export default {
    name: "ListCdtAnnonces",
    components: {
      SidebarDash
    },
    data() {
      return {
        postuler: [],
        loading: false,
        loadingGetPostuler: false,
        selectedFile: null,
        nom: "",
        prenom: "",
        email: "",
        phone: "",
        cv: "",
        motivation: "",
        page: 1,
        itemsPerPage: 6,
      };
    },

    mounted() {
      this.postulerList();
    },

    computed: {
      displayedItems() {
        const startIndex = (this.page - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.postuler.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.postuler.length / this.itemsPerPage);
      },
    },

    methods: {

      async postulerList() {
        this.loadingGetPostuler = true;
        await this.$store.dispatch("staticdata/fetchCandidatPostuler").then(
          (response) => {
            this.postuler = response.data;
            this.loadingGetPostuler = false;
          },
          (error) => {
            console.log("error--- ", error);
            this.loadingGetPostuler = false;
          }
        );
      },

      goToPage(newPage) {
        if (newPage >= 1 && newPage <= this.totalPages) {
          this.page = newPage;
        }
      },
      handleFileChange(event) {
        this.selectedFile = event.target.files[0];
      }
  }
};
</script>

<style scoped>
  .content {
    margin-left: 250px; /* Largeur du sidebar */
    padding: 20px;
  }

  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-250px);
    }

    .sidebar.show {
      transform: translateX(0);
    }

    .content {
      margin-left: 0;
    }
  }
</style>