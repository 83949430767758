<template>
    <div class="DetailServices">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />
  
  
  
          <div class="container">
            <h1 class="mb-5" style="font-size: 30px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
              ENERGIE
            </h1>
          </div>    
  
  
            <div class="container p-5">
              <div class="mb-5 description" style="margin: 0px !important;">
  
              <div class="row mb-5">
                <div class="col-md-9 mb-3">
                  <span style="color: #61C7F2; font-weight: 600;">AGILES TELECOMS</span> 
                  <span style="font-size: 20px;"> vous propose des solutions d'énergies solaires adaptées aux réseaux télécoms.</span>
                </div><div class="col-md-3"></div>
              </div>

              <div class="row">
                    <h1>
                        Solutions d'alimentation hybride LionRock
                    </h1>
                    <div class="col-md-7">
                        <img src="" alt="">
                    </div>
                    <div class="col-md-5"></div>
             </div>
  
  
        
  
            </div>
          </div>
  
      </div>
      <!-- content End -->
      
  
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'DetailServices',
    components: {
      NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>
  /* :root {
      --primary: #61C7F2;
      --secondary: #F7A51B;
      --light: #EEF9FF;
      --dark: #091E3E;
  } */
  </style>
  