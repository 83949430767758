<template>
  <div class="container-fluid">
    <div class="row">
  
        <SidebarDash />
  
      <!-- Contenu Principal -->
      <div class="col">
          <main class="content">

              <h6 class="mb-5 mt-5" style="color: #F7A51B;">----------LISTE DES ANNONCES</h6>

              <section v-if="loadingGetAnnonce">
                  <div class="row g-5">
                      <div class="col-12 text-center">
                          <h4>Chargement en cours ...</h4>
                      </div>
                  </div>
              </section>

              <section v-else>
                <section v-if="annonces.length == 0">
                  <div class="row g-5">
                    <div class="col-12 text-center text-warning">
                      <h4>Aucune offre disponible</h4>
                    </div>
                  </div>
                </section>
                <section v-else>
                  <div class="row g-5">
                    <div
                      class="col-md-4 wow slideInUp"
                      v-for="index in annonces"
                      :key="index.id"
                      data-wow-delay="0.1s"
                    >
                      <router-link
                        :to="{ name: 'detailannonces', params: { id: index.id } }"
                      >
                        <div class="blog-item bg-light rounded overflow-hidden">
                          <div class="blog-img position-relative overflow-hidden">
                            <img
                              class="img-fluid"
                              src="../../../../assets/img/recrutementt.jpeg"
                              alt=""
                            />
                          </div>
                          <div class="p-4">
                            <h5 class="mb-3" style="font-weight: 500 !important">
                              - {{ index.title }}
                            </h5>
                            <p class="" style="color: #f7a51b">
                              {{ index.entreprise }}
                            </p>
                            <br /><br />


                            <div class="mb-3">
                                <!-- <router-link
                                    :to="{ name: 'editannonces', params: { id: index.id } }"
                                >
                                    <button class="btn" 
                                        style="background-color: #61C7F2; float: left !important; 
                                        color: white; font-size: 14px !important;"
                                        @click="goToEditAnnonce(index.id)">
                                        Modifier
                                    </button>
                                </router-link> -->

                                <button class="btn" 
                                    style="background-color: #f7a51b; float: right !important;
                                    color: white; font-size: 14px !important;" 
                                    @click="supprimerAnnonce(index.id)">
                                    Supprimer
                                </button>
                            </div>

                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
      
                  <!-- annonces pagination start -->
                  <div class="col-12 wow slideInUp mt-5" data-wow-delay="0.1s">
                    <nav aria-label="Page navigation">
                      <ul class="pagination pagination m-0">
                        <li
                          @click="goToPage(page - 1)"
                          :class="page == 1 ? 'page-item disabled' : 'page-item'"
                        >
                          <a
                            class="page-link rounded-0"
                            href="#"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true"
                              ><i class="bi bi-arrow-left"></i
                            ></span>
                          </a>
                        </li>

                        <li
                          v-for="p in totalPages"
                          :key="p"
                          @click="goToPage(p)"
                          :class="p == page ? 'page-item active' : 'page-item'"
                        >
                          <a class="page-link" href="#">{{ p }}</a>
                        </li>

                        <li
                          @click="goToPage(page + 1)"
                          :class="
                            page == totalPages ? 'disabled page-item' : 'page-item'
                          "
                        >
                          <a class="page-link rounded-0" href="#" aria-label="Next">
                            <span aria-hidden="true"
                              ><i class="bi bi-arrow-right"></i
                            ></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <!-- annonces pagination end -->
                </section>
              </section>

          </main>
      </div>
      <!-- Contenu Principal -->


    </div>
  </div>
</template>

<script>
import SidebarDash from '@/components/SidebarDash.vue'
import Swal from "sweetalert2";
import { mapActions } from 'vuex';
  
  export default {
    name: "ListAnnonces",

    components: {
        SidebarDash
    },

    data() {
      return {
        annonces: [],
        loading: false,
        loadingGetAnnonce: false,
        selectedFile: null,
        nom: "",
        prenom: "",
        email: "",
        phone: "",
        image: "",
        page: 1,
        itemsPerPage: 6,
      };
    },
  
    mounted() {
      this.annoncesList();
    },

    computed: {
      displayedItems() {
        const startIndex = (this.page - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.annonces.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.annonces.length / this.itemsPerPage);
      },
    },

    methods: {
      ...mapActions('user', ['deleteAnnoncesRequest']),

      goToEditAnnonce(id) {
      this.$router.push({ name: 'editannonces', params: { id } });
      },

      async annoncesList() {
        this.loadingGetAnnonce = true;
        await this.$store.dispatch("staticdata/fetchAnnonces").then(
          (response) => {
            this.annonces = response.data;
            this.loadingGetAnnonce = false;
          },
          (error) => {
            console.log("error--- ", error);
            this.loadingGetAnnonce = false;
          }
        );
      },

      async supprimerAnnonce(id) {
        const confirmation = await Swal.fire({
          title: 'Êtes-vous sûr ?',
          text: "Vous ne pourrez pas revenir en arrière !",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#61C7F2',
          cancelButtonColor: '#F7A51B',
          confirmButtonText: 'Oui, supprimer !',
          cancelButtonText: 'Annuler'
        });

        if (confirmation.isConfirmed) {
          try {
            await this.deleteAnnoncesRequest(id);
            Swal.fire(
              'Supprimé !',
              'L\'annonce a été supprimée.',
              'success'
            );
            // Rechargez la liste des annonces après suppression
            this.annoncesList();
          } catch (error) {
            Swal.fire(
              'Erreur',
              'Une erreur est survenue lors de la suppression de l\'annonce.',
              'error'
            );
          }
        }
      },

      goToPage(newPage) {
        if (newPage >= 1 && newPage <= this.totalPages) {
          this.page = newPage;
        }
      },

    }
  };

</script>

<style scoped>

  .content {
      margin-left: 250px; /* Largeur du sidebar */
      padding: 20px;
  }

  @media (max-width: 768px) {
    .content {
        margin-left: 0;
    }
  }
</style>






