import StaticDataService from '../services/staticdata.service';

export const staticdata = {
    namespaced: true,
    state: {
        Annonces: [],
        // formateur: [],
    },
    actions: {
        async fetchAnnonces({ commit }) {
            return StaticDataService.getAnnonces()
                .then(
                    response => {
                        commit('fetchAnnoncesSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchAnnoncesFailure');
                        return Promise.reject(error);
                    }
                );
        },

        async getDetailAnnonces({ commit }, annonceId) {
            return StaticDataService.getDetailAnnonces(annonceId)
                .then(
                    response => {
                        commit('getDetailAnnoncesSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('getDetailAnnoncesFailure');
                        return Promise.reject(error);
                    }
                );
        },

        async fetchSpecificAnnonces({ commit }, annonceTitle) {
            return StaticDataService.getSpecificAnnonces(annonceTitle)
                .then(
                    response => {
                        commit('fetchSpecificAnnoncesSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchSpecificAnnoncesFailure');
                        return Promise.reject(error);
                    }
                );
        },

        async fetchRessources({ commit }) {
            return StaticDataService.getRessources()
                .then(
                    response => {
                        commit('fetchRessourcesSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchRessourcesFailure');
                        return Promise.reject(error);
                    }
                );
        },
        
        async fetchVoirRessources({ commit }, resourceId) {
            return StaticDataService.getVoirRessources(resourceId)
                .then(
                    response => {
                        commit('fetchVoirRessourcesSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchVoirRessourcesFailure');
                        return Promise.reject(error);
                    }
                );
        },

        async fetchFormatPostuler({ commit }) {
            return StaticDataService.getFormatPostuler()
                .then(
                    response => {
                        commit('fetchFormatPostulerSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFormatPostulerFailure');
                        return Promise.reject(error);
                    }
                );
        },
        async fetchCandidatPostuler({ commit }) {
            return StaticDataService.getCandidatPostuler()
                .then(
                    response => {
                        commit('fetchCandidatPostulerSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchCandidatPostulerFailure');
                        return Promise.reject(error);
                    }
                );
        }, 

        async fetchCandidatSpontannee({ commit }) {
            return StaticDataService.getCandidatSpontannee()
                .then(
                    response => {
                        commit('fetchCandidatSpontanneeSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchCandidatSpontanneeFailure');
                        return Promise.reject(error);
                    }
                );
        }, 


      
        
    },
    mutations: {
        fetchAnnoncesSuccess(state, annonces) {
            state.annonces = annonces
        },
        getDetailAnnoncesSuccess(state, annonces) {
            state.annonces = annonces
        },
        fetchRessourcesSuccess(state, agileressource) {
            state.agileressource = agileressource
        },
        fetchSpecificAnnoncesSuccess(state, annonces) {
            state.annonces = annonces
        },
        fetchFormatPostulerSuccess(state, formateur) {
            state.formateur = formateur;
        },
        fetchCandidatPostulerSuccess(state, postuler) {
            state.postuler = postuler
        },
        fetchCandidatSpontanneeSuccess(state, candidaturespontannee) {
            state.candidaturespontannee = candidaturespontannee
        },
        fetchVoirRessourcesSuccess(state, agileressource) {
            state.agileressource = agileressource
        }
    }
};