<template>
    <div class="Agiles_solution_items_one">
  
      <!-- Navbar & Carousel Start -->
      <div class="container-fluid position-relative p-0">
  
          <NavNavbarSI />


        <!-- AS detail Start -->
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <div class="container p-5">
                <!-- AS Detail Start -->
                <div class="mb-5" style="margin: 0px !important;">

                    <h1 class="mb-4" style="margin-bottom: 115px !important; font-size: 50px; background: linear-gradient( #61C7F2, #F7A51B ); -webkit-background-clip: text; color: transparent;">
                        <center>PERSONAL RING BACK TONE & VIDEO RING BACK TONE</center>
                    </h1>

                    <!-- <div>
                        <span class="Annonce mb-4"> 
                            Toute la solution complète pour maintenir les liaisons SIP sécurisées et l'accès des utilisateurs <br>

                            Plateforme unifiée qui combine le SBC et le contrôleur multimédia               
                        </span>
                    </div> -->
                    

                    <h3 class="Titre" style="margin-top: 65px !important; margin-bottom: 18px !important;">
                        Présentation du système
                    </h3>
                    


                    <div class="desc__titre">
                        <p class="Titre__items">
                            <span style="color: #61C7F2;"></span> 
                            RBT (Ring Back Tone) est l'un des SVA les plus populaires au cours des 5 dernières années. 
                            Sa pénétration continue de croître visiblement et génère des revenus stables pour les Opérateurs.
                            Le système PROTEI-RBT est un moyen rapide d'offrir le service Ring Back Tone aux abonnés. En raison de 
                            la flexibilité et de l'évolutivité de la plate-forme, le système peut être facilement ajusté en fonction 
                            des exigences actuelles ou nouvelles de l'opérateur. <br><br>

                            En plus des fonctionnalités traditionnelles (tonalités en fonction de l'identification de l'appelant et du calendrier, 
                            de la boîte à musique, des éléments de gestion des services tels que IVR, USSD, WEB), il existe de nombreuses options 
                            avancées, par exemple : Tonalité personnalisée, Séquence de Tonalité d’artiste, Corporate RBT, différents prix de tonalité 
                            par canal d'achat Hang-up SMS et Anti RBT. <br><br>

                            La solution permet d'étendre rapidement les outils promotionnels du service de l'opérateur, en formant de nouvelles propositions 
                            marketing telles que : "la deuxième mélodie en cadeau", "obtenir des points bonus pour inviter un ami", "période d'essai gratuite", 
                            "quatrième mélodie en cadeau », « Le premier mois d'abonnement est gratuit », etc. <br><br>
                            
                            L'option Vidéo RBT est prise en charge en tant qu'amélioration fonctionnelle importante de la plate-forme RBT traditionnelle, 
                            destinée aux utilisateurs 3G (minimum) pour lesquels des appels vidéo sont disponibles. Ce service est également accessible à partir 
                            des réseaux IMS et NGN. PROTEI-RBT est une solution économique, haute performance et évolutive avec un large éventail de fonctionnalités 
                            pour convenir aux opérateurs mobiles et aux fournisseurs de contenu nouveaux et existants. <br><br>

                            Les capacités de la plateforme vous permettent de fournir tout algorithme de tarification de service (abonné charge, charge pour la commande
                            de mélodies, etc.), le meilleur conforme au modèle économique d'Operateur ou fournisseur de services. <br><br>

                            <ul style="font-size: 21px !important;">
                                <li>Possibilité de contrôle via portail WEB, SMS ou demandes USSD, ou via IVR permet l'utilisation de</li>
                                <li>Il est facile pour les utilisateurs d'installer diverses fonctionnalités pour différents appelants et pour différents moments de la journée.</li>
                            </ul>

                        </p>
                    </div><br><br>


                    <div class="desc__avantages">

                        <h3 class="Titre">Avantages clés</h3>
                        <ul style="font-size: 21px !important; color: #052E4A;">
                            <li>Outil efficace pour réduire les coûts, l'espace et la consommation d'énergie</li>
                            <li>Prend en charge une large gamme de périphériques IP tiers</li>
                            <li>Système puissant d'interaction et prise en charge de la fonctionnalité de normalisation SIP</li>
                            <li>Prise en charge des mécanismes de routage pour une interconnexion transparente des réseaux</li>
                            <li>Protection de l'interface externe contre les attaques DoS, la fraude et l'interception via IP</li>
                            <li>Prise en charge du mode de sauvegarde</li>
                            <li>Mise à l'échelle horizontale</li>
                        </ul>
                        







                    </div>
                    
                </div>
                <!-- AS Detail End -->
            </div> 
        </div>
        <!-- AS detail End -->

          
      </div>
      <!-- Navbar & Carousel End -->
      
  
      <FooterView />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavNavbarSI from '@/components/NavNavbarSI.vue'
  import FooterView from '@/components/FooterView.vue'
  
  export default {
    name: 'Agiles_solution_items_one',
    components: {
        NavNavbarSI,
      FooterView
    }
  }
  </script>
  
  <style scoped>

    .Annonce{
        font-size: 20px; font-weight: 400; 
    }
    .Titre{
        color: #F7A51B; font-weight: 500;
    }
    .Sous__titre{
        color: #052E4A; 
    }
    .Titre__items{
        font-size: 21px;
    }

  </style>